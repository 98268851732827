import axios from "axios";
import AppConfig from "../constants/config";
import { getReqConfig } from "../utils/authUtils";

function setLoading(dispatch, loading) {
  return Promise.resolve(
    dispatch({
      type: "CAREMPTIES_LOADING",
      data: loading,
    })
  );
}

/**
 * Set an Error Message
 */
export function setError(message) {
  return (dispatch) =>
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: "CAREMPTIES_ERROR",
          data: message,
        })
      )
    );
}

function getErrorObject(err) {
  if (err && err.response && err.response.data && err.response.data) {
    return err.response.data;
  }
  return err;
}

export function getCaremptiesNoPaging() {
  // const api = `${AppConfig.backendApi}/carempties/listAll?paging=0`;
  const api = `${AppConfig.backendApi}/carempties/listAll?page=1&perPage=10`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      await setLoading(dispatch, true);

      const headers = await getReqConfig();
      return axios
        .get(api, { headers })
        .then(async (response) => {
          const { data } = response;

          await setLoading(dispatch, false);

          return resolve(
            dispatch({
              type: "CAREMPTIES_REPLACE",
              data: data || [],
            })
          );
        })
        .catch(async (err) => {
          await setLoading(dispatch, false);

          reject(getErrorObject(err));
        });
    });
}

export function updateCarempty(id, data) {
  const api = `${AppConfig.backendApi}/carempties/${id}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();
      axios
        .patch(api, data, { headers })
        .then((response) => {
          resolve(
            dispatch({
              type: "CAREMPTIES_UPDATE",
              data: response.data,
            })
          );
        })
        .catch((err) => {
          reject(getErrorObject(err));
        });
    });
}
