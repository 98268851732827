// eslint-disable-next-line import/no-anonymous-default-export
export default {
  search: {
    searchMember: true,
    searchDriver: false,
    name: '',
    identifier: '',
    address: '',
    truckTypes: [],
    sourceAddresses: [],
    targetAddresses: [],
  },
  members: [],
  drivers: [],
  locations: [],
};
