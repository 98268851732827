export const showConsoleLog = (tag, info) => {
  const jsonString = JSON.stringify(info, null, 2);

  if (jsonString && jsonString !== "{}") {
    console.log(`${tag} ${jsonString}`);
  } else {
    console.log(`${tag} ${info}`);
  }
};

export const showConsoleError = (tag, error) => {
  const jsonString = JSON.stringify(error, null, 2);

  if (jsonString && jsonString !== "{}") {
    console.log(`${tag} ${jsonString}`);
  } else {
    console.log(`${tag} ${error}`);
  }
};

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, "g"), replace);
}

export const formatterCurrency = new Intl.NumberFormat("vn-VI", {
  style: "currency",
  currency: "VND",
});
