import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getOtherFbOrdersByOwnerInfo,
  setError,
} from "../actions/fborderOthers";

class FbOrderOthersContainer extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    member: PropTypes.shape({}).isRequired,
    fborderOthers: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      fborders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
    getOtherFbOrdersByOwnerInfo: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    match: null,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    const { match } = this.props;

    if (match && match.params) {
      const { nid, wall, fones } = match.params;

      this.props.getOtherFbOrdersByOwnerInfo(nid, wall, fones).catch((err) => {
        if (err?.status || err?.statusText) {
          this.props.setError(`${err?.status} ${err?.statusText}`);
        } else {
          this.props.setError(err && err.message ? err.message : "ERROR");
        }
      });
    }
  };

  render = () => {
    const { Layout, member, fborderOthers } = this.props;

    return (
      <Layout
        member={member}
        error={fborderOthers.error}
        loading={fborderOthers.loading}
        fborders={fborderOthers.fborders}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  member: state.member || {},
  fborderOthers: state.fborderOthers || {},
});

const mapDispatchToProps = {
  getOtherFbOrdersByOwnerInfo,
  setError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FbOrderOthersContainer);
