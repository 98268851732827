import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCaremptiesNoPaging,
  setError,
  updateCarempty,
} from "../actions/carempties";

class CaremptyListing extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    Layout: PropTypes.func.isRequired,
    carempties: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      carempties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      page: PropTypes.number,
      perPage: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    getCaremptiesNoPaging: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    updateCarempty: PropTypes.func.isRequired,
  };

  static defaultProps = {
    match: null,
    location: null,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.fetchCarempties();
  };

  fetchCarempties = () =>
    this.props.getCaremptiesNoPaging().catch((err) => {
      this.props.setError(err && err.message ? err.message : "ERROR");
    });

  render = () => {
    const { member, history, Layout, carempties, match, updateCarempty } =
      this.props;
    const id =
      match && match.params && match.params.id ? match.params.id : null;

    return (
      <Layout
        member={member}
        history={history}
        id={id}
        error={carempties.error}
        loading={carempties.loading}
        carempties={carempties.carempties}
        page={carempties.page}
        perPage={carempties.perPage}
        reFetch={() => this.fetchCarempties()}
        updateCarempty={(id, data) => updateCarempty(id, data)}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  member: state.member || {},
  carempties: state.carempties || {},
});

const mapDispatchToProps = {
  getCaremptiesNoPaging,
  updateCarempty,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaremptyListing);
