import moment from 'moment';
import Store from '../store/carempties';
import { secondsToDate } from '../utils/dateUtils';
import { floatToCurrency } from '../utils/stringUtils';
import {
  getCityLabelByValue,
  getWeightTypeLabelByValue2,
} from '../utils/constantUtils';

const _ = require('lodash');

export const initialState = Store;

const marshalData = (original) => {
  original.forEach((element, index) => {
    element.cityStartString = getCityLabelByValue(element.cityStart);
    element.cityEndString = getCityLabelByValue(element.cityEnd);
    if (element.timeStart) {
      const tmpTimeStart = secondsToDate(element.timeStart);
      element.timeStartString = moment(tmpTimeStart).format('YY-MM-DD HH:mm');
    }
    if (element.price) {
      element.priceString = floatToCurrency(element.price);
    }

    if (element.tmpcar) {
      element.numberCar = element.tmpcar.numberCar;
      element.weight = `${element.tmpcar.weight} ${getWeightTypeLabelByValue2(element.tmpcar.typeWeight)}`;
      // element.createdString = moment(element.created).format('YY-MM-DD HH:mm');
    }

    if (element.tmpcustomer) {
      element.fullName = element.tmpcustomer.fullName;
      if (element.tmpcustomer.fone && element.tmpcustomer.fone.length > 0) {
        element.fone = _.join(element.tmpcustomer.fone, ',');
      }
    }
  });

  return original;
};

export default function caremptyReducer(state = initialState, action) {
  switch (action.type) {
    case 'CAREMPTIES_LOADING': {
      return {
        ...state,
        loading: action.data,
      };
    }
    case 'CAREMPTIES_ERROR': {
      return {
        ...state,
        error: action.data,
      };
    }
    case 'CAREMPTIES_REPLACE': {
      const carempties = marshalData(action.data);

      return {
        ...state,
        error: null,
        loading: false,
        carempties,
      };
    }
    case 'CAREMPTIES_REMOVE': {
      for (let index = 0; index < state.carempties.length; index++) {
        const element = state.carempties[index];
        if (element._id === action.data) {
          return {
            ...state,
            carempties: [
              ...state.carempties.slice(0, index),
              ...state.carempties.slice(index + 1),
            ],
          };
        }
      }
      break;
    }
    case 'CAREMPTIES_UPDATE': {
      const carempties = state.carempties.map((element) => {
        if (element._id !== action.data._id) {
          // This isn't the item we care about - keep it as-is
          return element;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...element,
          ...action.data,
        };
      });

      return {
        ...state,
        carempties,
      };
    }
    default:
      return state;
  }
}
