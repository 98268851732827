import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Col, Row } from "reactstrap";
import FbOrderItem from "./FbOrderItem";
import Loading from "./Loading";

class FbOrderOthersComponent extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    fborders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render = () => {
    const { member, error, loading, fborders } = this.props;

    if (loading) return <Loading />;

    const cards = fborders.map((item) => (
      <FbOrderItem key={item._id} member={member} item={item} />
    ));

    return (
      <div>
        <Row className={loading ? "content-loading" : ""}>
          <Col sm="12">
            {!!error && <Alert color="danger">{error}</Alert>}
            {cards}
          </Col>
        </Row>
      </div>
    );
  };
}

export default FbOrderOthersComponent;
