import _ from 'lodash';
import {
  truckTypes, addresses, truckKinds, cities, weightTypes, weightTypes2,
} from '../constants/constants';

export function getTruckTypeLabelByValue(value) {
  const list = _.filter(truckTypes, item => item.key === value);

  if (list && list.length > 0) {
    return list[0].name;
  }

  return null;
}

export function getTruckKindLabelByValue(value) {
  const list = _.filter(truckKinds, item => item.key === value);

  if (list && list.length > 0) {
    return list[0].name;
  }

  return null;
}

export function getAddressLabelByValue(value) {
  const list = _.filter(addresses, item => item.key === value);

  if (list && list.length > 0) {
    return list[0].name;
  }

  return null;
}

export function getCityLabelByValue(value) {
  const list = _.filter(cities, item => item.key === value);

  if (list && list.length > 0) {
    return list[0].name;
  }

  return null;
}

export function getWeightTypeLabelByValue(value) {
  const list = _.filter(weightTypes, item => item.key === value);

  if (list && list.length > 0) {
    return list[0].name;
  }

  return null;
}

export function getWeightTypeLabelByValue2(value) {
  const list = _.filter(weightTypes2, item => item.key === value);

  if (list && list.length > 0) {
    return list[0].name;
  }

  return null;
}
