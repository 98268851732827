import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getRequestsNoPaging,
  setError,
  updateRequest,
} from "../actions/requests";

class RequestListing extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    Layout: PropTypes.func.isRequired,
    requests: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      requests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      page: PropTypes.number,
      perPage: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    getRequestsNoPaging: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    updateRequest: PropTypes.func.isRequired,
  };

  static defaultProps = {
    match: null,
    location: null,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.fetchRequests();
  };

  fetchRequests = () =>
    this.props.getRequestsNoPaging().catch((err) => {
      this.props.setError(err && err.message ? err.message : "ERROR");
    });

  render = () => {
    const { member, history, Layout, requests, match, updateRequest } =
      this.props;
    const id =
      match && match.params && match.params.id ? match.params.id : null;

    return (
      <Layout
        member={member}
        history={history}
        id={id}
        error={requests.error}
        loading={requests.loading}
        requests={requests.requests}
        page={requests.page}
        perPage={requests.perPage}
        reFetch={() => this.fetchRequests()}
        updateRequest={(id, data) => updateRequest(id, data)}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  member: state.member || {},
  requests: state.requests || {},
});

const mapDispatchToProps = {
  getRequestsNoPaging,
  updateRequest,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestListing);
