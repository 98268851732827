// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: true,
  error: null,
  fboffers: [],
  page: 1,
  perPage: 50,
  search: {
    postBy: null,
    truckTypes: [],
    addresses: [],
  },
};
