import moment from 'moment';
import Store from '../store/requests';
import { secondsToDate } from '../utils/dateUtils';
import { floatToCurrency } from '../utils/stringUtils';
import {
  getCityLabelByValue,
  getWeightTypeLabelByValue2,
} from '../utils/constantUtils';

const _ = require('lodash');

export const initialState = Store;

const marshalData = (original) => {
  original.forEach((element, index) => {
    element.cityStartString = getCityLabelByValue(element.cityStart);
    element.cityEndString = getCityLabelByValue(element.cityEnd);
    if (element.timeStart) {
      const tmpTimeStart = secondsToDate(element.timeStart);
      element.timeStartString = moment(tmpTimeStart).format('YY-MM-DD');
    }
    if (element.priceGood) {
      element.priceGoodString = floatToCurrency(element.priceGood);
    }
    if (element.weigh) {
      element.weightString = `${element.weigh} ${getWeightTypeLabelByValue2(element.typeWeigh)}`;
    }

    if (element.idUser) {
      element.fullName = element.idUser.fullName;
      if (element.idUser.fone && element.idUser.fone.length > 0) {
        element.fone = _.join(element.idUser.fone, ',');
      }
    }

    if (element.auctions) {
      element.auctions.forEach((ele2, idx2) => {
        if (ele2.idUser) {
          ele2.fullName = ele2.idUser.fullName;
          if (ele2.idUser.fone && ele2.idUser.fone.length > 0) {
            ele2.fone = _.join(ele2.idUser.fone, ',');
          }
        }
      });
    }
  });

  return original;
};

export default function requestReducer(state = initialState, action) {
  switch (action.type) {
    case 'REQUESTS_LOADING': {
      return {
        ...state,
        loading: action.data,
      };
    }
    case 'REQUESTS_ERROR': {
      return {
        ...state,
        error: action.data,
      };
    }
    case 'REQUESTS_REPLACE': {
      const requests = marshalData(action.data);

      return {
        ...state,
        error: null,
        loading: false,
        requests,
      };
    }
    case 'REQUESTS_REMOVE': {
      for (let index = 0; index < state.requests.length; index++) {
        const element = state.requests[index];
        if (element._id === action.data) {
          return {
            ...state,
            requests: [
              ...state.requests.slice(0, index),
              ...state.requests.slice(index + 1),
            ],
          };
        }
      }
      break;
    }
    case 'REQUESTS_UPDATE': {
      const requests = state.requests.map((element) => {
        if (element._id !== action.data._id) {
          // This isn't the item we care about - keep it as-is
          return element;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...element,
          ...action.data,
        };
      });

      return {
        ...state,
        requests,
      };
    }
    default:
      return state;
  }

  return null;
}
