import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Loading from "./Loading";
import {
  OrderStatus,
  OrderStatusDefault,
  addresses as _addresses,
  truckTypes as _truckTypes,
} from "../../constants/constants";
import FbOrderItem from "./FbOrderItem";

class FbOrderListing extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    fborders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    search: PropTypes.shape({}).isRequired,
    page: PropTypes.number.isRequired,
    dropDownOpen: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    dropDownToggleLabel: PropTypes.string.isRequired,
    dropDownToggleValue: PropTypes.number.isRequired,
    onDropDownItemClick: PropTypes.func.isRequired,
    changeFbOrderStatus: PropTypes.func.isRequired,
    reFetch: PropTypes.func.isRequired,
    replaceProperty: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    dropDownOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalQuotation: false,
      modalFeedback: false,
      modalCaller: false,
      modalViewer: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleQuotation = this.toggleQuotation.bind(this);
    this.toggleFeedback = this.toggleFeedback.bind(this);

    this.ios15 =
      navigator.userAgent &&
      (navigator.userAgent.indexOf("iPhone OS 15") >= 0 ||
        navigator.userAgent.indexOf("iPhone OS 16") >= 0 ||
        navigator.userAgent.indexOf("iPhone OS 17") >= 0 ||
        navigator.userAgent.indexOf("iPhone OS 18") >= 0);
  }

  componentWillMount() {
    const { search } = this.props;
    this.setState({ search });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleQuotation = (fbOrder) => {
    if (fbOrder) {
      if (fbOrder.quotations && fbOrder.quotations.length > 0) {
        this.setState({
          modalQuotation: !this.state.modalQuotation,
          quotations: fbOrder.quotations,
        });
      }
    } else {
      this.setState({
        modalQuotation: !this.state.modalQuotation,
      });
    }
  };

  toggleViewer = (fbOrder) => {
    if (fbOrder) {
      if (fbOrder.viewers && fbOrder.viewers.length > 0) {
        this.setState({
          modalViewer: !this.state.modalViewer,
          viewers: fbOrder.viewers,
        });
      }
    } else {
      this.setState({
        modalViewer: !this.state.modalViewer,
      });
    }
  };

  toggleCaller = (fbOrder) => {
    if (fbOrder) {
      if (fbOrder.callers && fbOrder.callers.length > 0) {
        this.setState({
          modalCaller: !this.state.modalCaller,
          callers: fbOrder.callers,
        });
      }
    } else {
      this.setState({
        modalCaller: !this.state.modalCaller,
      });
    }
  };

  toggleFeedback = (fbOrder) => {
    if (fbOrder) {
      if (fbOrder.feedbacks && fbOrder.feedbacks.length > 0) {
        this.setState({
          modalFeedback: !this.state.modalFeedback,
          feedbacks: fbOrder.feedbacks,
        });
      }
    } else {
      this.setState({
        modalFeedback: !this.state.modalFeedback,
      });
    }
  };

  toggleAndSearch = () => {
    const { reFetch, replaceProperty } = this.props;
    const { search } = this.state;
    replaceProperty("search", search)
      .then(() => {
        reFetch();
      })
      .then(() => {
        this.toggle();
      });
  };

  handleChangeContent = (event) => {
    const { search } = this.state;
    search.content = event.target.value;
    this.setState({
      search,
    });
  };

  handleChangePostBy = (event) => {
    const { search } = this.state;
    search.postBy = event.target.value;
    this.setState({
      search,
    });
  };

  handleChangePhoneNumber = (event) => {
    const { search } = this.state;
    search.phoneNumber = event.target.value;
    this.setState({
      search,
    });
  };

  handleSelectChangeTruckTypes = (selectedOption) => {
    const { search } = this.state;
    search.truckTypes = selectedOption;
    this.setState({
      search,
    });
  };

  handleSelectChangeAddressesStart = (selectedOption) => {
    const { search } = this.state;
    search.addressesStart = selectedOption;
    this.setState({
      search,
    });
  };

  handleSelectChangeAddressesEnd = (selectedOption) => {
    const { search } = this.state;
    search.addressesEnd = selectedOption;
    this.setState({
      search,
    });
  };

  render = () => {
    const {
      member,
      error,
      loading,
      fborders,
      page,
      dropDownOpen,
      toggle,
      dropDownToggleLabel,
      dropDownToggleValue,
      onDropDownItemClick,
      changeFbOrderStatus,
      reFetch,
    } = this.props;

    if (loading) return <Loading />;

    if (!member.roleSale && !member.roleSalemng && !member.roleAdmin) {
      return <Redirect to="/" />;
    }

    // const getAddressButtonList = (addresses) => {
    //   const result = [];
    //   const list = addresses;
    //   if (list && list.length > 0) {
    //     list.forEach((element, index) => {
    //       const item = (
    //         <abbr className="small font-italic" key={element.key}>
    //           {element.name + (index < list.length - 1 ? ' - ' : '')}
    //         </abbr>
    //       );
    //       result.push(item);
    //     });
    //   }

    //   return result;
    // };

    const toCurrency = (numberString) => {
      const number = parseFloat(numberString);
      return number.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      });
    };

    // // Error
    // if (error) return <Error content={error} />;

    // Build Cards for Listing
    const cards = fborders.map((item) => (
      <FbOrderItem
        key={item._id}
        item={item}
        member={member}
        dropDownToggleValue={dropDownToggleValue}
        changeFbOrderStatus={changeFbOrderStatus}
        toggleCaller={this.toggleCaller}
        toggleFeedback={this.toggleFeedback}
        toggleQuotation={this.toggleQuotation}
        toggleViewer={this.toggleViewer}
        ios15={this.ios15}
      />
    ));

    const linkPrevious = `/?p=${parseInt(page, 10) - 1}`;
    const linkNext = `/?p=${parseInt(page, 10) + 1}`;

    // Show Listing
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Cấu hình bộ lọc</ModalHeader>
          <ModalBody>
            <Form onSubmit={(event) => this.handleSubmit(event)}>
              <FormGroup>
                <Label for="name">Người đăng</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.search.postBy}
                  onChange={this.handleChangePostBy}
                />
              </FormGroup>
              <FormGroup>
                <Label for="phoneNumber">Điện thoại</Label>
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder=""
                  value={this.state.search.phoneNumber}
                  onChange={this.handleChangePhoneNumber}
                />
              </FormGroup>
              <FormGroup>
                <Label for="content">Nội dung</Label>
                <Input
                  type="text"
                  name="content"
                  id="content"
                  placeholder=""
                  value={this.state.search.content}
                  onChange={this.handleChangeContent}
                />
              </FormGroup>
              <FormGroup>
                <Label for="truckTypes">Loại xe</Label>
                <Select
                  name="truckTypes"
                  id="truckTypes"
                  value={this.state.search.truckTypes}
                  onChange={this.handleSelectChangeTruckTypes}
                  options={_truckTypes}
                  isMulti
                  getOptionValue={(option) => option.key}
                  getOptionLabel={(option) => option.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="addressesStart">Tỉnh đi</Label>
                <Select
                  name="addressesStart"
                  id="addressesStart"
                  value={this.state.search.addressesStart}
                  onChange={this.handleSelectChangeAddressesStart}
                  options={_addresses}
                  isMulti
                  getOptionValue={(option) => option.key}
                  getOptionLabel={(option) => option.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="addresses">Tỉnh đến</Label>
                <Select
                  name="addressesEnd"
                  id="addressesEnd"
                  value={this.state.search.addressesEnd}
                  onChange={this.handleSelectChangeAddressesEnd}
                  options={_addresses}
                  isMulti
                  getOptionValue={(option) => option.key}
                  getOptionLabel={(option) => option.name}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleAndSearch}>
              OK
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Thoát
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.modalQuotation}
          toggle={() => this.toggleQuotation()}
        >
          <ModalHeader toggle={() => this.toggleQuotation()}>
            Danh sách báo giá
          </ModalHeader>
          <ModalBody>
            <ReactTable
              data={this.state.quotations}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Họ tên",
                  id: "firstName",
                  accessor: (d) => d.firstName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["firstName"] }),
                  filterAll: true,
                },
                {
                  Header: "Điện thoại",
                  id: "phoneNumber",
                  accessor: (d) => d.phoneNumber,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["phoneNumber"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Ghi chú",
                  id: "description",
                  accessor: (d) => d.description,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["description"] }),
                  filterAll: true,
                },

                {
                  Header: "Loại xe",
                  id: "truckTypeName",
                  accessor: (d) => d.truckTypeName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["truckTypeName"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Báo giá",
                  id: "quotation",
                  accessor: (d) => d.quotation,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["quotation"] }),
                  filterAll: true,
                  style: {
                    textAlign: "right",
                  },
                  Cell: (props) => <div>{toCurrency(props.value)}</div>,
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
              previousText="Trước"
              nextText="Sau"
              loadingText="Dữ liệu đang được tải..."
              noDataText="Không tồn tại dữ liệu"
              pageText="Trang"
              ofText="của"
              rowsText="dòng"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggleQuotation()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.modalViewer}
          toggle={() => this.toggleViewer()}
        >
          <ModalHeader toggle={() => this.toggleViewer()}>
            Danh sách người xem
          </ModalHeader>
          <ModalBody>
            <ReactTable
              data={this.state.viewers}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Họ tên",
                  id: "firstName",
                  accessor: (d) => d.firstName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["firstName"] }),
                  filterAll: true,
                },
                {
                  Header: "Điện thoại",
                  id: "phoneNumber",
                  accessor: (d) => d.phoneNumber,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["phoneNumber"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Ngày xem",
                  id: "viewedDate",
                  accessor: (d) =>
                    moment(d.viewedDate).local().format("DD/MM hh:mm"),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["viewedDate"] }),
                  filterAll: true,
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
              previousText="Trước"
              nextText="Sau"
              loadingText="Dữ liệu đang được tải..."
              noDataText="Không tồn tại dữ liệu"
              pageText="Trang"
              ofText="của"
              rowsText="dòng"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggleViewer()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.modalCaller}
          toggle={() => this.toggleCaller()}
        >
          <ModalHeader toggle={() => this.toggleCaller()}>
            Danh sách người gọi
          </ModalHeader>
          <ModalBody>
            <ReactTable
              data={this.state.callers}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Họ tên",
                  id: "firstName",
                  accessor: (d) => d.firstName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["firstName"] }),
                  filterAll: true,
                },
                {
                  Header: "Điện thoại",
                  id: "phoneNumber",
                  accessor: (d) => d.phoneNumber,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["phoneNumber"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Ngày gọi",
                  id: "calledDate",
                  accessor: (d) =>
                    moment(d.calledDate).local().format("DD/MM hh:mm"),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["calledDate"] }),
                  filterAll: true,
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
              previousText="Trước"
              nextText="Sau"
              loadingText="Dữ liệu đang được tải..."
              noDataText="Không tồn tại dữ liệu"
              pageText="Trang"
              ofText="của"
              rowsText="dòng"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggleCaller()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.modalFeedback}
          toggle={() => this.toggleFeedback()}
        >
          <ModalHeader toggle={() => this.toggleFeedback()}>
            Danh sách báo cáo
          </ModalHeader>
          <ModalBody>
            <ReactTable
              data={this.state.feedbacks}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Họ tên",
                  id: "firstName",
                  accessor: (d) => d.firstName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["firstName"] }),
                  filterAll: true,
                },
                {
                  Header: "Điện thoại",
                  id: "phoneNumber",
                  accessor: (d) => d.phoneNumber,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["phoneNumber"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Lý do",
                  id: "feedback",
                  accessor: (d) => d.feedback,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["feedback"] }),
                  filterAll: true,
                },
                {
                  Header: "Ghi chú",
                  id: "description",
                  accessor: (d) => d.description,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["description"] }),
                  filterAll: true,
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
              previousText="Trước"
              nextText="Sau"
              loadingText="Dữ liệu đang được tải..."
              noDataText="Không tồn tại dữ liệu"
              pageText="Trang"
              ofText="của"
              rowsText="dòng"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggleFeedback()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Row style={{ marginTop: 5, marginBottom: 5 }}>
          <Col>
            <Row>
              <Col>
                <Dropdown isOpen={dropDownOpen} toggle={toggle}>
                  <DropdownToggle caret>{dropDownToggleLabel}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      value={OrderStatusDefault}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                    >
                      Ds Mới
                    </DropdownItem>
                    <DropdownItem
                      value={OrderStatus.ORDER_APPROVED}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                    >
                      Ds Hàng
                    </DropdownItem>
                    <DropdownItem
                      value={OrderStatus.ORDER_IGNORED}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                    >
                      Ds Hoãn
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem
                      value={OrderStatus.ORDER_TRUCK_OWNER}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                    >
                      Ds Xe
                    </DropdownItem>
                    <DropdownItem
                      value={OrderStatus.ORDER_REJECTED}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                    >
                      Ds Huỷ
                    </DropdownItem>

                    <DropdownItem divider hidden={!member.roleAdmin} />
                    <DropdownItem
                      value={OrderStatus.ORDER_POTENTIAL_APPROVED}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                      hidden={!member.roleAdmin}
                    >
                      ⚠️⚠️⚠️ Ds Hàng
                    </DropdownItem>
                    <DropdownItem
                      value={OrderStatus.ORDER_POTENTIAL_TRUCK_OWNER}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                      hidden={!member.roleAdmin}
                    >
                      ⚠️⚠️⚠️ Ds Xe
                    </DropdownItem>
                    <DropdownItem
                      value={OrderStatus.ORDER_POTENTIAL_REJECTED}
                      onClick={(s) => {
                        onDropDownItemClick(s);
                      }}
                      hidden={!member.roleAdmin}
                    >
                      ⚠️⚠️⚠️ Ds Huỷ
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => {
                        reFetch();
                      }}
                    >
                      <i className="icon-refresh" /> Tải
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
              <Col
                className={
                  dropDownToggleValue === OrderStatusDefault
                    ? "col-md-auto text-right"
                    : "d-none"
                }
              >
                <Link className="btn btn-secondary" to="/fborder/NEW">
                  <i className="icon-plus" />
                </Link>
              </Col>
              <Col className="col-md-auto text-right">
                <Button onClick={this.toggle}>
                  <i className="icon-magnifier" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={loading ? "content-loading" : ""}>
          <Col sm="12">
            {!!error && <Alert color="danger">{error}</Alert>}
            {cards}
          </Col>
        </Row>
        <Row className={loading ? "content-loading" : ""}>
          <Col sm="12" className="text-right">
            <Button
              onClick={() => {
                reFetch();
              }}
              className="btn btn-dark"
            >
              <i className="icon-refresh" /> Tải
            </Button>{" "}
            <Link className="btn btn-dark" to={linkPrevious}>
              <i className="icon-arrow-left" /> Trước
            </Link>{" "}
            <Link className="btn btn-dark" to={linkNext}>
              Sau <i className="icon-arrow-right" />
            </Link>
          </Col>
        </Row>
      </div>
    );
  };
}

export default FbOrderListing;
