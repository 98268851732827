import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getFbOrders,
  setError,
  changeFbOrderStatus,
  updateFbOrder,
  createFbOrder,
  replaceProperty,
} from "../actions/fborders";
import { logout } from "../actions/member";
import { OrderStatusDefault } from "../constants/constants";

const qs = require("query-string");

class FbOrderListing extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    Layout: PropTypes.func.isRequired,
    fborders: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      fborders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      page: PropTypes.number,
      perPage: PropTypes.number,
      search: PropTypes.shape({}).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    getFbOrders: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    changeFbOrderStatus: PropTypes.func.isRequired,
    updateFbOrder: PropTypes.func.isRequired,
    createFbOrder: PropTypes.func.isRequired,
    replaceProperty: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    match: null,
    location: null,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      dropDownOpen: false,
      dropDownToggleValue: OrderStatusDefault,
      dropDownToggleLabel: "Phân loại",
    };
  }

  componentDidMount = () => {
    this.fetchFbOrders(1, this.props.fborders.perPage);
  };

  componentDidUpdate(prevProps) {
    const { location, fborders } = this.props;

    const oldPage =
      prevProps.location &&
      prevProps.location.search &&
      qs.parse(prevProps.location.search).p;
    const newPage = location && location.search && qs.parse(location.search).p;

    if (newPage && newPage !== oldPage) {
      this.fetchFbOrders(parseInt(newPage, 10), fborders.perPage);
    }
  }

  onDropDownItemClick = (s) => {
    this.setState(
      {
        dropDownToggleLabel: s.currentTarget.textContent,
        dropDownToggleValue: parseInt(
          s.currentTarget.getAttribute("value"),
          10
        ),
      },
      () => this.fetchFbOrders(1, this.props.fborders.perPage)
    );
  };

  fetchFbOrders = (page, perPage) =>
    this.props
      .getFbOrders(
        page,
        perPage,
        this.state.dropDownToggleValue,
        this.props.fborders.search
      )
      .catch((err) => {
        if (err?.status || err?.statusText) {
          if (err?.status === 401) {
            const { logout, history } = this.props;
            logout().then(() => history.push("/login"));
            return;
          }
          this.props.setError(`${err?.status} ${err?.statusText}`);
        } else {
          this.props.setError(err && err.message ? err.message : "ERROR");
        }
      });

  toggle() {
    this.setState((prevState) => ({
      dropDownOpen: !prevState.dropDownOpen,
    }));
  }

  render = () => {
    const {
      member,
      history,
      Layout,
      fborders,
      match,
      changeFbOrderStatus,
      updateFbOrder,
      createFbOrder,
      replaceProperty,
    } = this.props;
    const id =
      match && match.params && match.params.id ? match.params.id : null;

    const { dropDownOpen, dropDownToggleLabel, dropDownToggleValue } =
      this.state;

    return (
      <Layout
        member={member}
        history={history}
        id={id}
        error={fborders.error}
        loading={fborders.loading}
        fborders={fborders.fborders}
        search={fborders.search}
        page={fborders.page}
        perPage={fborders.perPage}
        reFetch={() =>
          this.fetchFbOrders(1, fborders.perPage, dropDownToggleValue)
        }
        dropDownOpen={dropDownOpen}
        dropDownToggleLabel={dropDownToggleLabel}
        dropDownToggleValue={dropDownToggleValue}
        toggle={() => this.toggle()}
        onDropDownItemClick={(s) => this.onDropDownItemClick(s)}
        changeFbOrderStatus={changeFbOrderStatus}
        updateFbOrder={(id, data) => updateFbOrder(id, data)}
        createFbOrder={(data) => createFbOrder(data)}
        replaceProperty={(name, data) => replaceProperty(name, data)}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  member: state.member || {},
  fborders: state.fborders || {},
});

const mapDispatchToProps = {
  getFbOrders,
  setError,
  changeFbOrderStatus,
  updateFbOrder,
  createFbOrder,
  replaceProperty,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(FbOrderListing);
