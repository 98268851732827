import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import ErrorMessages from "../../constants/errors";
import Loading from "./Loading";
import Error from "./Error";
import {
  addresses as _addresses,
  truckTypes as _truckTypes,
  weightTypes as _weightTypes,
} from "../../constants/constants";
// import PlacesWithStandaloneSearchBox from "./SearchBox";

class FbOrderView extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    fborders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    updateFbOrder: PropTypes.func.isRequired,
    createFbOrder: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    const { id, fborders } = this.props;

    let fborder = null;
    if (id === "NEW") {
      fborder = {
        postBy: "Chủ_hàng_A",
        postDate: new Date(),
        postWall: "http://timhangnhanh.com.vn/",
        postLink: "",
        phoneNumber: "0966671880",
        message: "",
        weight: "",
        status: -1,
        statusDetector: 122,
        truckTypes: [],
        addresses: [],
        loadingAddress: "",
        loadingLocation: "",
      };
    } else if (id && fborders && fborders.length > 0) {
      fborder = fborders.find((item) => item._id === id);

      fborder.postWall = fborder.postWall ? fborder.postWall : "";
      fborder.postLink = fborder.postLink ? fborder.postLink : "";
      fborder.phoneNumber = fborder.phoneNumber ? fborder.phoneNumber : "";
      fborder.weight = fborder.weight ? fborder.weight : "";

      fborder.loadingAddress = fborder.loadingAddress
        ? fborder.loadingAddress
        : "";
      fborder.loadingLocation = fborder.loadingLocation
        ? JSON.stringify(fborder.loadingLocation)
        : "";
    }

    this.setState(fborder);
  }

  convertToSelectDataSource = (input) => {
    const result = [];
    if (input && input.length > 0) {
      input.forEach((element) => {
        result.push({
          value: element.key,
          label: element.name,
        });
      });
    }

    return result;
  };

  convertFromSelectDataSource = (input) => {
    const result = [];
    if (input && input.length > 0) {
      input.forEach((element) => {
        result.push({
          key: element.value,
          name: element.label,
        });
      });
    }

    return result;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeLocation = (formattedAddress, location) => {
    this.setState({
      loadingAddress: formattedAddress,
      loadingLocation: location,
    });
  };

  handleSelectChangeTruckTypes = (selectedOption) => {
    this.setState({
      truckTypes: selectedOption,
    });
  };

  handleSelectChangeAddresses = (selectedOption) => {
    this.setState({
      addresses: selectedOption,
    });
  };

  handleSelectChangeWeightType = (selectedOption) => {
    this.setState({
      weightType: selectedOption,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { updateFbOrder, createFbOrder, history, id } = this.props;

    if (id === "NEW") {
      createFbOrder(this.state)
        .then(() => {
          window.scrollTo(0, 0);
          history.push("/");
        })
        .catch((err) => {
          this.setState({
            err: err && err.message ? err.message : "ERROR",
          });
        });
    } else {
      updateFbOrder(this.state._id, this.state)
        .then(() => {
          window.scrollTo(0, 0);
          history.push("/");
        })
        .catch((err) => {
          this.setState({
            err: err && err.message ? err.message : "ERROR",
          });
        });
    }
  };

  render = () => {
    const { loading, id, history } = this.props;
    const {
      err,
      _id,
      postBy,
      message,
      commodity,
      weight,
      weightType,
      postWall,
      postLink,
      phoneNumber,
      truckTypes,
      addresses,
      loadingAddress,
      loadingLocation,
    } = this.state;
    const header = id === "NEW" ? "Tạo mới đơn hàng" : "Cập nhật đơn hàng";

    // Loading
    if (loading) return <Loading />;

    // // Error
    // if (error) return <Error content={error} />;

    // FbOrder not found
    if (!_id && id !== "NEW") {
      return <Error content={ErrorMessages.fborder404} />;
    }

    return (
      <div>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col>{header}</Col>
                  <Col>
                    <div className="float-right">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={(event) => this.handleSubmit(event)}
                      >
                        OK
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="postBy">Người tạo</Label>
                        <Input
                          type="text"
                          name="postBy"
                          id="postBy"
                          placeholder=""
                          value={postBy}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="phoneNumber">Điện thoại</Label>
                        <Input
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder=""
                          value={phoneNumber}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="addresses">Tỉnh</Label>
                        <Select
                          name="addresses"
                          id="addresses"
                          value={addresses}
                          onChange={this.handleSelectChangeAddresses}
                          options={_addresses}
                          isMulti
                          getOptionValue={(option) => option.key}
                          getOptionLabel={(option) => option.name}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="truckTypes">Loại xe</Label>
                        <Select
                          name="truckTypes"
                          id="truckTypes"
                          value={truckTypes}
                          onChange={this.handleSelectChangeTruckTypes}
                          options={_truckTypes}
                          isMulti
                          getOptionValue={(option) => option.key}
                          getOptionLabel={(option) => option.name}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="commodity">NDTB (Hàng hóa)</Label>
                        <Input
                          type="textarea"
                          rows={1}
                          name="commodity"
                          id="commodity"
                          placeholder=""
                          value={commodity}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="weight">Khối lượng</Label>
                        <Input
                          type="number"
                          name="weight"
                          id="weight"
                          placeholder=""
                          value={weight}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="weightType">Đơn vị</Label>
                        <Select
                          name="weightType"
                          id="weightType"
                          value={weightType}
                          onChange={this.handleSelectChangeWeightType}
                          options={_weightTypes}
                          isMulti={false}
                          getOptionValue={(option) => option.key}
                          getOptionLabel={(option) => option.name}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="message">Thông báo</Label>
                    <Input
                      type="textarea"
                      rows={3}
                      name="message"
                      id="message"
                      placeholder=""
                      value={message}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="loadingAddressSearch">Điểm bốc hàng</Label>
                        <Input
                          type="text"
                          name="loadingAddressSearch"
                          id="loadingAddressSearch"
                          placeholder="Tìm kiếm"
                          // value={loadingAddress}
                          onChange={this.handleChange}
                          onChangeLocation={this.handleChangeLocation}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="loadingAddress">&nbsp;</Label>
                        <Input
                          type="text"
                          name="loadingAddress"
                          id="loadingAddress"
                          placeholder=""
                          value={loadingAddress}
                          disabled
                          // onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <FormGroup>
                        <Label for="loadingLocation">&nbsp;</Label>
                        <Input
                          type="text"
                          name="loadingLocation"
                          id="loadingLocation"
                          placeholder=""
                          value={loadingLocation}
                          disabled
                          // onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="postWall">Trang chủ</Label>
                    <Input
                      type="text"
                      name="postWall"
                      id="postWall"
                      placeholder=""
                      value={postWall}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="postLink">Liên kết</Label>
                    <Input
                      type="text"
                      name="postLink"
                      id="postLink"
                      placeholder=""
                      value={postLink}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {!!err && <Alert color="danger">{err}</Alert>}
                  <Button
                    color="primary"
                    onClick={(event) => this.handleSubmit(event)}
                  >
                    OK
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push("/");
                    }}
                  >
                    <i className="icon-arrow-left" /> Danh sách
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
}

export default FbOrderView;
