import { matchSorter } from "match-sorter";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import ReactTable from "react-table";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { licensePeriods as _licensePeriods } from "../../constants/constants";
import { formatterCurrency } from "../../utils/commonUtil";
import Loading from "./Loading";

const LICENSE_DEFAULT = {
  bank: "",
  account: "",
  amount: 0,
  licensePeriod: undefined,
  description: "",
};

class MemberLicenseListing extends Component {
  static propTypes = {
    roleAdmin: PropTypes.bool.isRequired,
    roleSale: PropTypes.bool.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    getMembersForLicenseFunction: PropTypes.func.isRequired,
    addMemberLicense: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      message: null,
      selectedMember: null,
      upsertLicense: LICENSE_DEFAULT,
      upsertLicenseError: null,
      licenses: [],
      modalLicenses: false,
      modalLicenseAdd: false,
      modalInfo: false,
      textSearch: "",
      memberInfo: "",
      total: 0,
      totalIndex: 0,
    };
  }

  componentDidMount = () => {
    this.fetchMembers();
  };

  fetchMembers = () => {
    const { getMembersForLicenseFunction } = this.props;
    const { textSearch } = this.state;

    let textSearchTmp = textSearch || "";

    textSearchTmp = textSearchTmp.replace(/\s/g, "");

    if (textSearchTmp.startsWith("0")) {
      textSearchTmp = textSearchTmp.substring(1);
    }
    if (textSearchTmp.startsWith("+84")) {
      textSearchTmp = textSearchTmp.substring(3);
    }

    this.setState({ loading: true });
    return getMembersForLicenseFunction(textSearchTmp)
      .then(() => {
        const { members } = this.props;
        let indexEnd,
          totalTmp = 0;
        for (let index = 0; index < members.length; index++) {
          if (index < members.length - 1) {
            const item = members[index];
            const item2 = members[index + 1];
            const itemDate = moment(item.licenseUpdated);
            const itemDate2 = moment(item2.licenseUpdated);
            if (itemDate.date() >= 15 && itemDate2.date() <= 14) {
              indexEnd = index;
              break;
            }
          }
        }

        for (let index = 0; index <= indexEnd; index++) {
          totalTmp += members[index].licenseCurrent.amount;
        }

        this.setState({
          totalIndex: indexEnd + 1,
          total: totalTmp,
        });
      })
      .then(() => this.setState({ loading: false }))
      .catch((err) => {
        this.setState({
          loading: false,
          error: err && err.message ? err.message : "ERROR",
        });
      });
  };

  toggleModalLicenses = (member) => {
    const { modalLicenses } = this.state;

    if (member) {
      if (member.licenses && member.licenses.length > 0) {
        this.setState({
          modalLicenses: !modalLicenses,
          licenses: member.licenses,
        });
      }
    } else {
      this.setState({
        modalLicenses: !modalLicenses,
      });
    }
  };

  toggleModalInfo = (member) => {
    const { modalInfo } = this.state;

    if (member) {
      this.setState({
        modalInfo: !modalInfo,
        memberInfo: JSON.stringify(member, undefined, 2),
      });
    } else {
      this.setState({
        modalInfo: !modalInfo,
      });
    }
  };

  toggleModalLicenseAdd = (member) => {
    const { modalLicenseAdd } = this.state;

    if (member) {
      this.setState({
        modalLicenseAdd: !modalLicenseAdd,
        selectedMember: member,
      });
    } else {
      this.setState({
        modalLicenseAdd: !modalLicenseAdd,
      });
    }
  };

  toCurrency = (numberString) => {
    const number = parseFloat(numberString);
    return number.toLocaleString("it-IT", {
      style: "currency",
      currency: "VND",
    });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleInputChangeUpsertLicense = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    const { upsertLicense } = { ...this.state };
    const upsertLicenseTmp = upsertLicense;

    upsertLicenseTmp[name] = value;

    this.setState({ upsertLicense: upsertLicenseTmp });
  };

  handleSelectChangeUpsertLicense = (value, target) => {
    const { name } = target;

    const { upsertLicense } = { ...this.state };
    const upsertLicenseTmp = upsertLicense;

    upsertLicenseTmp[name] = value;

    if (name === "licensePeriod") {
      switch (value.key) {
        case 1:
          upsertLicenseTmp.amount = 300000;
          break;
        case 3:
          upsertLicenseTmp.amount = 600000;
          break;
        case 6:
          upsertLicenseTmp.amount = 900000;
          break;
        case 12:
          upsertLicenseTmp.amount = 1200000;
          break;
        case 99:
          upsertLicenseTmp.amount = 9999000;
          break;

        default:
          upsertLicenseTmp.amount = value.key * 300000;
          break;
      }
    }

    this.setState({ upsertLicense: upsertLicenseTmp });
  };

  handleSubmitUpsertLicense = (event) => {
    event.preventDefault();

    const { addMemberLicense } = this.props;

    const { upsertLicense, selectedMember } = this.state;

    addMemberLicense(selectedMember._id, upsertLicense)
      .then(() => {
        this.upsertLicenseReset();

        this.setState({
          error: null,
          upsertLicenseError: null,
          message: "Thêm mới License thành công.",
        });
        this.toggleModalLicenseAdd();
      })
      .catch((err) => {
        this.setState({
          error: null,
          message: null,
          upsertLicenseError: JSON.stringify(err),
        });
      });
  };

  handleKeyPressTextSearch = (target) => {
    if (target.charCode === 13) {
      this.fetchMembers();
    }
  };

  render = () => {
    const {
      roleAdmin,
      // roleSale,
      members,
    } = this.props;

    const {
      loading,
      error,
      message,
      licenses,
      memberInfo,
      modalLicenses,
      modalLicenseAdd,
      modalInfo,
      textSearch,
      upsertLicense,
      upsertLicenseError,
      totalIndex,
      total,
    } = this.state;

    if (loading) return <Loading />;

    if (!roleAdmin) {
      return <Redirect to="/" />;
    }

    // // Error
    // if (error) return <Error content={error} />;

    // Show Listing
    return (
      <div>
        <Modal
          size="lg"
          isOpen={modalLicenses}
          toggle={() => this.toggleModalLicenses()}
        >
          <ModalHeader toggle={() => this.toggleModalLicenses()}>
            Danh sách license
          </ModalHeader>
          <ModalBody>
            <ReactTable
              data={licenses}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Lần",
                  id: "licenseNumber",
                  accessor: (d) => d.licenseNumber,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["licenseNumber"],
                    }),
                  filterAll: true,
                },
                {
                  Header: "Tài khoản",
                  id: "account",
                  accessor: (d) => d.account,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["account"] }),
                  filterAll: true,
                },
                {
                  Header: "Số tiền",
                  id: "amount",
                  accessor: (d) => d.amount,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["amount"] }),
                  filterAll: true,
                  style: {
                    textAlign: "right",
                  },
                  Cell: (props) => <div>{this.toCurrency(props.value)}</div>,
                },
                {
                  Header: "Loại",
                  id: "periodName",
                  accessor: (d) => d.licensePeriod.name,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["periodName"],
                    }),
                  filterAll: true,
                },
                {
                  Header: "Bắt đầu",
                  id: "licenseActivated",
                  accessor: (d) =>
                    moment(d.licenseActivated).local().format("YYYY-MM-DD"),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["licenseActivated"],
                    }),
                  filterAll: true,
                },
                {
                  Header: "Kết thúc",
                  id: "licenseExpired",
                  accessor: (d) =>
                    moment(d.licenseExpired).local().format("YYYY-MM-DD"),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["licenseExpired"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Ghi chú",
                  id: "description",
                  accessor: (d) => d.description,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["description"] }),
                  filterAll: true,
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
              previousText="Trước"
              nextText="Sau"
              loadingText="Dữ liệu đang được tải..."
              noDataText="Không tồn tại dữ liệu"
              pageText="Trang"
              ofText="của"
              rowsText="dòng"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggleModalLicenses()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={modalInfo}
          toggle={() => this.toggleModalInfo()}
        >
          <ModalHeader toggle={() => this.toggleModalInfo()}>
            Thông tin chi tiết
          </ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              name="memberInfo"
              id="memberInfo"
              placeholder=""
              value={memberInfo}
              onChange={() => {}}
              rows={10}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggleModalInfo()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={modalLicenseAdd}
          toggle={() => this.toggleModalLicenseAdd()}
        >
          <ModalHeader toggle={() => this.toggleModalLicenseAdd()}>
            Tạo mới License
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="bank">Ngân hàng</Label>
                <Input
                  type="text"
                  name="bank"
                  id="bank"
                  placeholder=""
                  value={upsertLicense.bank}
                  onChange={this.handleInputChangeUpsertLicense}
                />
              </FormGroup>
              <FormGroup>
                <Label for="account">Tài khoản</Label>
                <Input
                  type="text"
                  name="account"
                  id="account"
                  placeholder=""
                  value={upsertLicense.account}
                  onChange={this.handleInputChangeUpsertLicense}
                />
              </FormGroup>

              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <FormGroup>
                    <Label for="licensePeriod">Chu kỳ</Label>
                    <Select
                      name="licensePeriod"
                      id="licensePeriod"
                      value={upsertLicense.licensePeriod}
                      onChange={this.handleSelectChangeUpsertLicense}
                      options={_licensePeriods}
                      isMulti={false}
                      getOptionValue={(option) => option.key}
                      getOptionLabel={(option) => option.name}
                      placeholder="Chọn chu kỳ"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <FormGroup>
                    <Label for="amount">Số tiền</Label>
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      placeholder=""
                      value={upsertLicense.amount}
                      onChange={this.handleInputChangeUpsertLicense}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="description">Ghi chú</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder=""
                  value={upsertLicense.description}
                  onChange={this.handleInputChangeUpsertLicense}
                  rows={3}
                />
              </FormGroup>

              {!!upsertLicenseError && (
                <Alert color="danger">{upsertLicenseError}</Alert>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSubmitUpsertLicense}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <div style={{ height: "100vh", width: "99.9%" }}>
          <Row style={{ paddingBottom: 5 }}>
            <Col>
              <span>
                {totalIndex} - {formatterCurrency.format(total)}
              </span>
            </Col>
            <Col className="col-md-auto text-right">
              <Input
                type="text"
                name="textSearch"
                id="textSearch"
                placeholder=""
                value={textSearch}
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPressTextSearch}
              />
            </Col>
          </Row>
          <Row className={loading ? "content-loading" : ""}>
            <Col sm="12">
              {!!error && <Alert color="danger">{error}</Alert>}
              {!!message && <Alert color="success">{message}</Alert>}
              <ReactTable
                data={members}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    Header: "Họ tên",
                    id: "firstName",
                    accessor: (d) => d.firstName,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["firstName"] }),
                    filterAll: true,
                  },
                  {
                    Header: "Số ĐT",
                    id: "phoneNumber",
                    accessor: (d) => d.phoneNumber,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["phoneNumber"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: "Email",
                    id: "email",
                    accessor: (d) => d.email,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["email"] }),
                    filterAll: true,
                  },
                  {
                    Header: "Ngày",
                    id: "licenseUpdated",
                    accessor: (d) =>
                      d.licenseUpdated
                        ? moment(d.licenseUpdated).local().format("YYYY-MM-DD")
                        : "",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["licenseUpdated"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: "Đăng nhập",
                    id: "lastLoggedDate",
                    accessor: (d) =>
                      d.lastLoggedDate
                        ? moment(d.lastLoggedDate).local().format("YYYY-MM-DD")
                        : "",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["lastLoggedDate"],
                      }),
                    filterAll: true,
                  },
                  {
                    id: "licenses",
                    width: 135,
                    accessor: (d) => d._id,
                    filterable: false,
                    Cell: (value) => {
                      const { original } = value;
                      return (
                        <div>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              className="btn btn-success btn-sm"
                              onClick={() =>
                                this.toggleModalLicenseAdd(original)
                              }
                            >
                              +
                            </Button>
                            &nbsp;
                            <Button
                              hidden={
                                !original.licenses ||
                                original.licenses.length <= 0
                              }
                              className="btn btn-secondary btn-sm"
                              style={{ width: "50px" }}
                              onClick={() => this.toggleModalLicenses(original)}
                            >
                              [
                              {original.licenses ? original.licenses.length : 0}
                              ]
                            </Button>
                            &nbsp;
                            <Button
                              className="btn btn-secondary btn-sm"
                              onClick={() => this.toggleModalInfo(original)}
                            >
                              ?
                            </Button>
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                previousText="Trước"
                nextText="Sau"
                loadingText="Dữ liệu đang được tải..."
                noDataText="Không tồn tại dữ liệu"
                pageText="Trang"
                ofText="của"
                rowsText="dòng"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  upsertLicenseReset = () => {
    const { upsertLicense } = this.state;

    upsertLicense.bank = "";
    upsertLicense.account = "";
    upsertLicense.licensePeriod = undefined;
    upsertLicense.amount = 0;
    upsertLicense.description = "";
  };
}

export default MemberLicenseListing;
