import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';
import Loading from './Loading';

const CustomerListing = ({
  roleAdmin,
  error,
  loading,
  customers,
  page,
  perPage,
  reFetch,
}) => {
  if (loading) return <Loading />;

  if (!roleAdmin) {
    return <Redirect to="/" />;
  }

  const data = customers;

  // // Error
  // if (error) return <Error content={error} />;

  // Show Listing
  return (
    <div style={{ height: '100vh', width: '99.9%' }}>
      <Row>
        <Col>
          {/* <h1>
            Người dùng
          </h1> */}
        </Col>
      </Row>
      <Row className={loading ? 'content-loading' : ''}>
        <Col sm="12">
          {!!error && (
            <Alert color="danger">
              {error}
            </Alert>
          )}
          <ReactTable
            data={data}
            filterable
            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
            columns={[
              {
                Header: 'Họ tên',
                id: 'fullName',
                accessor: d => d.fullName,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['fullName'] }),
                filterAll: true,
              },
              {
                Header: 'Email',
                id: 'email',
                accessor: d => d.email,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['email'] }),
                filterAll: true,
              },
              {
                Header: 'Điện thoại',
                id: 'fone',
                width: 150,
                accessor: d => d.fone,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['fone'] }),
                filterAll: true,
              },
              {
                Header: 'TK',
                id: 'chuxeString',
                width: 50,
                accessor: d => d.chuxeString,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['chuxeString'] }),
                filterAll: true,
              },
              {
                Header: 'Ngày tạo',
                id: 'createdString',
                width: 130,
                accessor: d => d.createdString,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['createdString'] }),
                filterAll: true,
              },
              {
                Header: 'KH?',
                id: 'status',
                width: 50,
                accessor: d => d.status,
                filterable: false,
                Cell: ({ value }) => {
                  if (value === 'active') {
                    return (
                      <div style={{ textAlign: 'center', color: 'blue' }}>
                        <i className="icon-check" />
                      </div>
                    );
                  }
                  return <div />;
                },
              },
              {
                id: 'edit',
                width: 100,
                accessor: d => d._id,
                filterable: false,
                Cell: ({ value }) => (
                  <div style={{ textAlign: 'center' }}>
                    <Link className="btn btn-success btn-sm" to={`/customer/${value}`}>
                      <i className="icon-pencil" />
                      {' '}
                      Sửa
                    </Link>
                  </div>
                ),
              },
            ]}
            defaultPageSize={10}
            className="-striped -highlight"
            previousText="Trước"
            nextText="Sau"
            loadingText="Dữ liệu đang được tải..."
            noDataText="Không tồn tại dữ liệu"
            pageText="Trang"
            ofText="của"
            rowsText="dòng"
          />
        </Col>
      </Row>
    </div>
  );
};

CustomerListing.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  reFetch: PropTypes.func.isRequired,
};

CustomerListing.defaultProps = {
  error: null,
};

export default CustomerListing;
