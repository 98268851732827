import ErrorMessages from "../constants/errors";
import statusMessage from "./status";
import AppConfig from "../constants/config";
import { getReqConfig } from "../utils/authUtils";

/**
 * Login with Email/Password
 */
export function login(formData) {
  const { email, password } = formData;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      await statusMessage(dispatch, "loading", true);

      // Validation checks
      if (!email) return reject({ message: ErrorMessages.missingEmail });
      if (!password) return reject({ message: ErrorMessages.missingPassword });

      const api = `${AppConfig.backendApi}/auth/login`;
      const data = {
        email,
        password,
      };

      const headers = await getReqConfig();

      // eslint-disable-next-line no-undef
      const response = await fetch(api, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseJson = await response.json();

        const { token, user } = responseJson;
        token.email = email;

        localStorage.setItem("token", JSON.stringify(token));

        await statusMessage(dispatch, "loading", false);

        const data = {
          uid: user.id,
          email: user.email,
          emailVerified: true,
          firstName: user.name || "Noname",
          lastName: user.name,
          signedUp: undefined,
          role: user.role,
          roleAdmin: user.role === "admin",
          roleSale: user.role === "sale",
          roleSalemng: user.role === "salemng",
          roleUser: user.role === "user",
        };
        return resolve(
          dispatch({
            type: "USER_LOGIN",
            data,
          })
        );
      } else {
        reject({
          message: `Invalid email or password (${response.status} ${response.statusText})`,
        });
      }
    }).catch(async (err) => {
      await statusMessage(dispatch, "loading", false);
      throw err.message;
    });
}

/**
 * Logout
 */
export function logout() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      await statusMessage(dispatch, "loading", true);

      localStorage.clear();

      dispatch({ type: "USER_RESET" });

      // setTimeout(async () => {
      await statusMessage(dispatch, "loading", false);
      resolve();
    }).catch(async (err) => {
      console.error(err);
      await statusMessage(dispatch, "error", err.message);
      throw err.message;
    });
}

export function getMembersForLicenseFunction(phoneNumber = "") {
  const api = `${AppConfig.backendApi}/members?page=1&perPage=111&li=1&i=${phoneNumber}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();

      return fetch(api, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson.code && responseJson.code >= 300) {
            throw responseJson;
          }

          const members = responseJson;

          return resolve(
            dispatch({
              type: "MEMBER_LICENSE_LIST",
              data: !members ? [] : members,
            })
          );
        })
        .catch((error) => {
          reject(error);
        });
    }).catch(async (e) => {
      console.warn(e);
    });
}

export function addMemberLicense(memberId, license) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const data = license;

      const api = `${AppConfig.backendApi}/members/addLicense/${memberId}`;

      const headers = await getReqConfig();

      // eslint-disable-next-line no-undef
      return fetch(api, {
        method: "PATCH",
        headers,
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.code && responseJson.code >= 300) {
            throw responseJson;
          }

          return resolve(
            dispatch({
              type: "MEMBER_LICENSE_ADD",
              data: responseJson,
            })
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
}
