import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import Loading from './Loading';
import { addresses as _addresses, truckTypes as _truckTypes } from '../../constants/constants';

const styles = {
  card: {
    marginBottom: 5,
  },
};

class FbOfferListing extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    fboffers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    search: PropTypes.shape({}).isRequired,
    page: PropTypes.number.isRequired,
    dropDownOpen: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    dropDownToggleLabel: PropTypes.string.isRequired,
    dropDownToggleValue: PropTypes.number.isRequired,
    onDropDownItemClick: PropTypes.func.isRequired,
    changeFbOfferStatus: PropTypes.func.isRequired,
    reFetch: PropTypes.func.isRequired,
    replaceProperty: PropTypes.func.isRequired,
  }

  static defaultProps = {
    error: null,
    dropDownOpen: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    const { search } = this.props;

    this.setState({ search });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleAndSearch = () => {
    const { reFetch, replaceProperty } = this.props;
    const { search } = this.state;

    replaceProperty('search', search)
      .then(() => { reFetch(); })
      .then(() => { this.toggle(); });
  }

  handleChangeContent = (event) => {
    const { search } = this.state;
    search.content = event.target.value;
    this.setState({
      search,
    });
  };

  handleChangePostBy = (event) => {
    const { search } = this.state;
    search.postBy = event.target.value;
    this.setState({
      search,
    });
  }

  handleSelectChangeTruckTypes = (selectedOption) => {
    const { search } = this.state;
    search.truckTypes = selectedOption;
    this.setState({
      search,
    });
  }

  handleSelectChangeAddressesStart = (selectedOption) => {
    const { search } = this.state;
    search.addressesStart = selectedOption;
    this.setState({
      search,
    });
  };

  handleSelectChangeAddressesEnd = (selectedOption) => {
    const { search } = this.state;
    search.addressesEnd = selectedOption;
    this.setState({
      search,
    });
  };

  render = () => {
    const {
      member,
      error,
      loading,
      fboffers,
      page,
      dropDownOpen,
      toggle,
      dropDownToggleLabel,
      dropDownToggleValue,
      onDropDownItemClick,
      changeFbOfferStatus,
      reFetch,
    } = this.props;

    if (loading) return <Loading />;

    if (!member.roleSale && !member.roleSalemng && !member.roleAdmin) {
      return <Redirect to="/" />;
    }

    const getAddressButtonList = (addresses) => {
      const result = [];
      const list = addresses;
      if (list && list.length > 0) {
        list.forEach((element, index) => {
          const item = (
            <abbr className="small font-italic" key={element.key}>
              {element.name + ((index < list.length - 1) ? ' - ' : '')}
            </abbr>
          );
          result.push(item);
        });
      }

      return result;
    };

    // Build Cards for Listing
    const cards = fboffers.map(item => (
      <Card key={`${item._id}`} style={styles.card}>
        <CardBody>
          <CardTitle>
            {!!item.postWall && (
              <a href={item.postWall} target="_blank" rel="noopener noreferrer">
                {item.postBy}
              </a>
            )}
            {!item.postWall && (
              item.postBy
            )}
            {' - '}
            <abbr className="small font-italic">
              {item.postDateFromNow}
            </abbr>
            <span className="small font-italic">
              {' ['}
            </span>
            {getAddressButtonList(item.addresses)}
            <span className="small font-italic">
              {']'}
            </span>
          </CardTitle>
          <CardText className="text-justify">
            {item.message}
          </CardText>
          <Row>
            <Col sm="3" className="text-center">
              <Link className="btn btn-success" to={`/fboffer/${item._id}`}>
                <i className="icon-pencil" />
                {' '}
                Edit
              </Link>
            </Col>
            <Col sm="3" className="text-center">
              <a className={item.postLink ? 'btn btn-success' : 'd-none'} href={item.postLink} target="_blank" rel="noopener noreferrer">
                <i className="icon-diamond" />
                {' '}
                Link
              </a>
            </Col>
            <Col sm="3" className="text-center">
              <Button hidden={member.roleSale} className={dropDownToggleValue !== 1 ? 'btn btn-success' : 'd-none'} onClick={() => { changeFbOfferStatus(item._id, 1); }}>
                <i className="icon-like" />
                {' '}
                OK
              </Button>
            </Col>
            <Col sm="3" className="text-center">
              <Button className={dropDownToggleValue !== 2 ? 'btn btn-warning' : 'd-none'} onClick={() => { changeFbOfferStatus(item._id, 2); }}>
                <i className="icon-dislike" />
                {' '}
                NOK
              </Button>
            </Col>
          </Row>

        </CardBody>
      </Card>
    ));

    const linkPrevious = `/fboffers?p=${parseInt(page, 10) - 1}`;
    const linkNext = `/fboffers?p=${parseInt(page, 10) + 1}`;

    // Show Listing
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Cấu hình bộ lọc
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={event => this.handleSubmit(event)}>
              <FormGroup>
                <Label for="name">Người đăng</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.search.postBy}
                  onChange={this.handleChangePostBy}
                />
              </FormGroup>
              <FormGroup>
                <Label for="content">Nội dung</Label>
                <Input
                  type="text"
                  name="content"
                  id="content"
                  placeholder=""
                  value={this.state.search.content}
                  onChange={this.handleChangeContent}
                />
              </FormGroup>
              <FormGroup>
                <Label for="truckTypes">
                  Loại xe
                </Label>
                <Select
                  name="truckTypes"
                  id="truckTypes"
                  value={this.state.search.truckTypes}
                  onChange={this.handleSelectChangeTruckTypes}
                  options={_truckTypes}
                  isMulti
                  getOptionValue={option => option.key}
                  getOptionLabel={option => option.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="addressesStart">Tỉnh đi</Label>
                <Select
                  name="addressesStart"
                  id="addressesStart"
                  value={this.state.search.addressesStart}
                  onChange={this.handleSelectChangeAddressesStart}
                  options={_addresses}
                  isMulti
                  getOptionValue={option => option.key}
                  getOptionLabel={option => option.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="addresses">Tỉnh đến</Label>
                <Select
                  name="addressesEnd"
                  id="addressesEnd"
                  value={this.state.search.addressesEnd}
                  onChange={this.handleSelectChangeAddressesEnd}
                  options={_addresses}
                  isMulti
                  getOptionValue={option => option.key}
                  getOptionLabel={option => option.name}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleAndSearch}>
              OK
            </Button>
            {' '}
            <Button color="secondary" onClick={this.toggle}>
              Thoát
            </Button>
          </ModalFooter>
        </Modal>
        <Row style={{ paddingBottom: 5 }}>
          <Col>
            {/* <h1>
            Xe tìm hàng
            </h1> */}
          </Col>
          <Col className="col-md-auto text-right">
            <Row>
              <Col>
                <Dropdown isOpen={dropDownOpen} toggle={toggle}>
                  <DropdownToggle caret>
                    {dropDownToggleLabel}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem value="0" onClick={(s) => { onDropDownItemClick(s); }}>
                      New
                    </DropdownItem>
                    <DropdownItem value="1" onClick={(s) => { onDropDownItemClick(s); }}>
                      OK List
                    </DropdownItem>
                    <DropdownItem value="2" onClick={(s) => { onDropDownItemClick(s); }}>
                      NOK List
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => { reFetch(); }}>
                      <i className="icon-refresh" />
                      {' '}
                      Refresh
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
              <Col className={dropDownToggleValue === 0 ? '' : 'd-none'}>
                <Link className="btn btn-secondary" to="/fboffer/NEW">
                  <i className="icon-plus" />
                  {' '}
                  New
                </Link>
              </Col>
              <Col>
                <Button onClick={this.toggle}>
                  <i className="icon-magnifier" />
                  {' '}
                  Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={loading ? 'content-loading' : ''}>
          <Col sm="12">
            {!!error && (
              <Alert color="danger">
                {error}
              </Alert>
            )}
            {cards}
          </Col>
        </Row>
        <Row className={loading ? 'content-loading' : ''}>
          <Col sm="12" className="text-right">
            <Link className="btn btn-dark" to={linkPrevious}>
              <i className="icon-arrow-left" />
              {' '}
              Prev
            </Link>
            {' '}
            <Link className="btn btn-dark" to={linkNext}>
              Next
              {' '}
              <i className="icon-arrow-right" />
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FbOfferListing;
