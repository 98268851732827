import Store from '../store/map';
import {
  getTruckKindLabelByValue,
  getWeightTypeLabelByValue,
} from '../utils/constantUtils';

export const initialState = Store;

const marshalDataMembers = (original) => {
  original.forEach((element) => {
    element.locs = [];
  });

  return original;
};

const marshalDataDrivers = (original) => {
  original.forEach((element) => {
    if (element.tmpcar) {
      if (element.tmpcar.typeCar) {
        element.truckKind = getTruckKindLabelByValue(element.tmpcar.typeCar);
      }

      if (element.tmpcar.weight && element.tmpcar.typeWeight) {
        element.weightString = `${element.tmpcar.weight} ${getWeightTypeLabelByValue(element.tmpcar.typeWeight)}`;
      }
    }

    if (element.tmpcustomer && element.tmpcustomer.company && element.tmpcustomer.company.gps) {
      element.customerGps = element.tmpcustomer.company.gps;
    }
  });

  return original;
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case 'MEMBERS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          members: marshalDataMembers(action.data),
        };
      }
      return initialState;
    }
    case 'MEMBER_UPDATE_LOCS': {
      if (action.uid && action.data) {
        for (let index = 0; index < state.members.length; index++) {
          const element = state.members[index];
          if (element.uid === action.uid) {
            element.locs.length = 0;
            element.locs.push(...action.data);
            break;
          }
        }

        return {
          ...state,
          members: [...state.members],
        };
      }
      return initialState;
    }
    case 'DRIVERS_REPLACE': {
      if (action.data) {
        const list = marshalDataDrivers(action.data);

        return {
          ...state,
          drivers: list,
        };
      }
      return initialState;
    }
    case 'LOCATIONS_REPLACE': {
      if (action.data) {
        const list = action.data;

        return {
          ...state,
          locations: list,
        };
      }
      return initialState;
    }
    case 'MEMBER_UPDATE': {
      if (action.data) {
        if (state.members && state.members.length > 0) {
          for (let index = 0; index < state.members.length; index += 1) {
            const member = state.members[index];

            if (member.uid === action.data.uid) {
              member.phoneNumber = action.data.phoneNumber;
              return {
                ...state,
              };
            }
          }
        }
      }
      return {
        ...state,
      };
    }
    case 'MAP_PROPERTY_REPLACE': {
      if (action.name && action.data) {
        return {
          ...state,
          [action.name]: action.data,
        };
      }
      break;
    }
    default:
      return state;
  }

  return null;
}
