const devMode = process.env.NODE_ENV !== "development";

const config = {
  // App Details
  appName: "THN Admin",

  // Build Configuration - eg. Debug or Release?
  DEV: devMode,

  // Google Analytics - uses a 'dev' account while we're testing
  gaTrackingId: devMode ? "UA-84284256-2" : "UA-84284256-1",

  googleMapApiKey: "AIzaSyD2FGIeolzrjGybhxl1yV357TLXjVkO0XY",

  // backendApi: "http://localhost:3000/z86",

  backendApi: "https://thnapi.vnloop.com/z86",
};

export default config;
