import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../actions/member';

class Member extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    memberLogout: PropTypes.func.isRequired,
    member: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      logged: PropTypes.bool,
    }).isRequired,
  }

  componentDidMount = () => {
  }

  render = () => {
    const { Layout, member, memberLogout } = this.props;

    if (!member.logged) {
      return <Redirect to="/login" />;
    }

    return <Layout member={member} logout={memberLogout} />;
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  memberLogout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Member);
