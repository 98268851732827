const floatToCurrency = (number) => number.toLocaleString("USD");

const capitalize = (s) => {
  if (typeof s !== "string") return "";

  let tmp = s.trim();
  tmp = tmp.replace(/  +/g, " ");

  tmp = tmp.charAt(0).toUpperCase() + tmp.slice(1);

  if (tmp.endsWith(":")) {
    tmp = tmp.slice(0, tmp.length - 1);
  }

  return tmp;
};

const trimParagraph = (paragraph) => paragraph.replace(/\s{1,}/g, " ").trim();

const replaceWords = (fullString, oldWords, newWords) => {
  const regex = new RegExp(`(^|\\W|_)(${oldWords})($|\\W|_)`, "gi");
  return fullString.replace(regex, `$1${newWords}$3`);
};

export { floatToCurrency, capitalize, replaceWords, trimParagraph };
