import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";
import Loading from "./Loading";

class RequestListing extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    requests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    reFetch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalAuctions: false,
    };
    this.toggleAuctions = this.toggleAuctions.bind(this);
  }

  componentWillMount() {}

  toggleAuctions = (request) => {
    if (request) {
      if (request.auctions && request.auctions.length > 0) {
        this.setState({
          modalAuctions: !this.state.modalAuctions,
          auctions: request.auctions,
        });
      }
    } else {
      this.setState({
        modalAuctions: !this.state.modalAuctions,
      });
    }
  };

  toCurrency = (numberString) => {
    const number = parseFloat(numberString);
    return number.toLocaleString("it-IT", {
      style: "currency",
      currency: "VND",
    });
  };

  render = () => {
    const { member, error, loading, requests } = this.props;

    if (loading) return <Loading />;

    if (!member.roleSale && !member.roleSalemng && !member.roleAdmin) {
      return <Redirect to="/" />;
    }

    const data = requests;

    // // Error
    // if (error) return <Error content={error} />;

    // Show Listing
    return (
      <div>
        <Modal
          size="lg"
          isOpen={this.state.modalAuctions}
          toggle={() => this.toggleAuctions()}
        >
          <ModalHeader toggle={() => this.toggleAuctions()}>
            Danh sách báo giá
          </ModalHeader>
          <ModalBody>
            <ReactTable
              data={this.state.auctions}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Họ tên",
                  id: "fullName",
                  accessor: (d) => d.fullName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["fullName"] }),
                  filterAll: true,
                },
                {
                  Header: "Điện thoại",
                  id: "fone",
                  accessor: (d) => d.fone,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["fone"],
                    }),
                  filterAll: true,
                },

                {
                  Header: "Ghi chú",
                  id: "note",
                  accessor: (d) => d.note,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["note"] }),
                  filterAll: true,
                },

                {
                  Header: "Báo giá",
                  id: "price",
                  accessor: (d) => d.price,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["price"] }),
                  filterAll: true,
                  style: {
                    textAlign: "right",
                  },
                  Cell: (props) => <div>{this.toCurrency(props.value)}</div>,
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
              previousText="Trước"
              nextText="Sau"
              loadingText="Dữ liệu đang được tải..."
              noDataText="Không tồn tại dữ liệu"
              pageText="Trang"
              ofText="của"
              rowsText="dòng"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggleAuctions()}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <div style={{ height: "100vh", width: "99.9%" }}>
          <Row>
            <Col>{/* <h1>Xe báo giá</h1> */}</Col>
          </Row>
          <Row className={loading ? "content-loading" : ""}>
            <Col sm="12">
              {!!error && <Alert color="danger">{error}</Alert>}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    Header: "Họ tên",
                    id: "fullName",
                    accessor: (d) => d.fullName,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["fullName"] }),
                    filterAll: true,
                  },
                  {
                    Header: "Số ĐT",
                    id: "fone",
                    accessor: (d) => d.fone,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["fone"] }),
                    filterAll: true,
                  },
                  {
                    Header: "Trọng tải",
                    id: "weightString",
                    accessor: (d) => d.weightString,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["weightString"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: "Đi",
                    id: "cityStartString",
                    accessor: (d) => d.cityStartString,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["cityStartString"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: "Đến",
                    id: "cityEndString",
                    accessor: (d) => d.cityEndString,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["cityEndString"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: "Ngày đóng",
                    id: "timeStartString",
                    accessor: (d) => d.timeStartString,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["timeStartString"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: "Giá",
                    id: "priceGoodString",
                    accessor: (d) => d.priceGoodString,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["priceGoodString"],
                      }),
                    filterAll: true,
                  },

                  {
                    Header: "Mặt hàng",
                    id: "productName",
                    accessor: (d) => d.productName,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["productName"],
                      }),
                    filterAll: true,
                  },

                  {
                    id: "auctions",
                    width: 100,
                    accessor: (d) => d._id,
                    filterable: false,
                    Cell: (value) => {
                      const { original } = value;
                      return (
                        <div style={{ textAlign: "center" }}>
                          <Button
                            hidden={
                              !original.auctions ||
                              original.auctions.length <= 0
                            }
                            className="btn btn-secondary btn-sm"
                            onClick={() => this.toggleAuctions(original)}
                          >
                            [{original.auctions ? original.auctions.length : 0}]
                            Báo giá
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                previousText="Trước"
                nextText="Sau"
                loadingText="Dữ liệu đang được tải..."
                noDataText="Không tồn tại dữ liệu"
                pageText="Trang"
                ofText="của"
                rowsText="dòng"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  };
}

export default RequestListing;
