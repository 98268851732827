import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
// import App from './App';
import "bootstrap/dist/css/bootstrap.css";
import "react-table/react-table.css";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";

import configureStore from "./store/index";
import Routes from "./web/routes/index";

// Components
import Loading from "./web/components/Loading";

// Load css
require("./web/styles/style.scss");

const { persistor, store } = configureStore();
// persistor.purge(); // Debug to clear persist

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Router>
        <Routes />
      </Router>
    </PersistGate>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
