import moment from 'moment';
import Store from '../store/customers';

export const initialState = Store;

const marshalData = (original) => {
  original.forEach((element, index) => {
    element.chuxeString = element.chuxe ? 'CX' : 'CH';
    element.createdString = moment(element.created).format('YY-MM-DD HH:mm');
  });

  return original;
};

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case 'CUSTOMERS_LOADING': {
      return {
        ...state,
        loading: action.data,
      };
    }
    case 'CUSTOMERS_ERROR': {
      return {
        ...state,
        error: action.data,
      };
    }
    case 'CUSTOMERS_REPLACE': {
      const customers = marshalData(action.data);

      return {
        ...state,
        error: null,
        loading: false,
        customers,
      };
    }
    case 'CUSTOMERS_REMOVE': {
      for (let index = 0; index < state.customers.length; index++) {
        const element = state.customers[index];
        if (element._id === action.data) {
          return {
            ...state,
            customers: [
              ...state.customers.slice(0, index),
              ...state.customers.slice(index + 1),
            ],
          };
        }
      }
      break;
    }
    case 'CUSTOMERS_UPDATE': {
      const customers = state.customers.map((element) => {
        if (element._id !== action.data._id) {
          // This isn't the item we care about - keep it as-is
          return element;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...element,
          ...action.data,
        };
      });

      return {
        ...state,
        customers,
      };
    }
    default:
      return state;
  }
}
