import Store from "../store/member";

export const initialState = Store;

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_LOGIN": {
      if (action.data) {
        return {
          ...state,
          loading: false,
          error: null,
          uid: action.data.uid,
          email: action.data.email,
          emailVerified: action.data.emailVerified,
          logged: true,
          //
          firstName: action.data.firstName,
          lastName: action.data.lastName,
          signedUp: action.data.signedUp,
          role: action.data.role,
          roleAdmin: action.data.roleAdmin,
          roleSale: action.data.roleSale,
          roleSalemng: action.data.roleSalemng,
          roleUser: action.data.roleUser,
        };
      }
      return initialState;
    }
    case "USER_ERROR": {
      if (action.data) {
        return {
          ...state,
          loading: false,
          error: action.data,
        };
      }
      return initialState;
    }
    case "USER_RESET": {
      return initialState;
    }
    case "MEMBER_LICENSE_LIST": {
      return {
        ...state,
        memberLicenses: action.data,
      };
    }
    case "MEMBER_LICENSE_ADD": {
      const memberId = action.data._id;
      const member = action.data;
      const { memberLicenses } = state;
      let memberLicensesTmp = null;

      for (let index = 0; index < memberLicenses.length; index++) {
        const element = memberLicenses[index];
        if (element._id === memberId) {
          memberLicensesTmp = [
            ...memberLicenses.slice(0, index),
            member,
            ...memberLicenses.slice(index + 1),
          ];
        }
      }

      return {
        ...state,
        memberLicenses: memberLicensesTmp,
      };
    }
    default:
      return state;
  }
}
