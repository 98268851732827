import React from "react";
import { Switch, Route } from "react-router-dom";

// Templates
import TemplateNothing from "../components/TemplateNothing";
import TemplateSidebar from "../components/TemplateSidebar";

// import TemplateSidebarMap from "../components/TemplateSidebarMap";

// Routes

import LoginContainer from "../../containers/Login";
import LoginComponent from "../components/Login";

// import TempContainer from "../../containers/Temp";
// import TempComponent from "../components/Temp";

// import MapViewContainer from "../../containers/MapView";
// import MapViewComponent from "../components/MapView";

import FbOrdersContainer from "../../containers/FbOrders";
import FbOrdersComponent from "../components/FbOrders";
import FbOrderViewComponent from "../components/FbOrder";

import FbOrderOthersContainer from "../../containers/FbOrderOthers";
import FbOrderOthersComponent from "../components/FbOrderOthers";

import FbOffersContainer from "../../containers/FbOffers";
import FbOffersComponent from "../components/FbOffers";
import FbOfferViewComponent from "../components/FbOffer";

import CustomersContainer from "../../containers/Customers";
import CustomersComponent from "../components/Customers";
import CustomerViewComponent from "../components/Customer";

import CaremptiesContainer from "../../containers/Carempties";
import CaremptiesComponent from "../components/Carempties";

import RequestsContainer from "../../containers/Requests";
import RequestsComponent from "../components/Requests";

import MemberLicensesContainer from "../../containers/MemberLicenses";
import MemberLicensesComponent from "../components/MemberLicenses";

import Error from "../components/Error";

const Index = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={(props) => (
        <TemplateSidebar>
          <FbOrdersContainer {...props} Layout={FbOrdersComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      exact
      path="/fborder-others/:nid/:wall/:fones"
      render={(props) => (
        <TemplateSidebar>
          <FbOrderOthersContainer {...props} Layout={FbOrderOthersComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/fborder/:id"
      render={(props) => (
        <TemplateSidebar>
          <FbOrdersContainer {...props} Layout={FbOrderViewComponent} />
        </TemplateSidebar>
      )}
    />
    {/* <Route
      path="/mapview"
      render={(props) => (
        <TemplateSidebarMap>
          <MapViewContainer {...props} Layout={MapViewComponent} />
        </TemplateSidebarMap>
      )}
    /> */}
    {/* <Route
      path="/temp"
      render={props => (
        <TemplateSidebar>
          <TempContainer {...props} Layout={TempComponent} />
        </TemplateSidebar>
      )}
    /> */}
    <Route
      path="/login"
      render={(props) => (
        <TemplateNothing>
          <LoginContainer {...props} Layout={LoginComponent} />
        </TemplateNothing>
      )}
    />

    <Route
      path="/fboffers"
      render={(props) => (
        <TemplateSidebar>
          <FbOffersContainer {...props} Layout={FbOffersComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/fboffer/:id"
      render={(props) => (
        <TemplateSidebar>
          <FbOffersContainer {...props} Layout={FbOfferViewComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/customers"
      render={(props) => (
        <TemplateSidebar>
          <CustomersContainer {...props} Layout={CustomersComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/customer/:id"
      render={(props) => (
        <TemplateSidebar>
          <CustomersContainer {...props} Layout={CustomerViewComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/carempties"
      render={(props) => (
        <TemplateSidebar>
          <CaremptiesContainer {...props} Layout={CaremptiesComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/requests"
      render={(props) => (
        <TemplateSidebar>
          <RequestsContainer {...props} Layout={RequestsComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/licenses"
      render={(props) => (
        <TemplateSidebar>
          <MemberLicensesContainer
            {...props}
            Layout={MemberLicensesComponent}
          />
        </TemplateSidebar>
      )}
    />
    <Route
      render={(props) => (
        <TemplateSidebar>
          <Error
            {...props}
            title="404"
            content="Sorry, the route you requested does not exist"
          />
        </TemplateSidebar>
      )}
    />
  </Switch>
);

export default Index;
