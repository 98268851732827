import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from 'reactstrap';
import Select from 'react-select';
import ErrorMessages from '../../constants/errors';
import Loading from './Loading';
import Error from './Error';
import { addresses as _addresses, truckTypes as _truckTypes } from '../../constants/constants';

class FbOfferView extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    fboffers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    updateFbOffer: PropTypes.func.isRequired,
    createFbOffer: PropTypes.func.isRequired,
  }

  static defaultProps = {
    error: null,
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentWillMount() {
    const { id, fboffers } = this.props;

    let fboffer = null;
    if (id === 'NEW') {
      fboffer = {
        postBy: 'Tài xế B',
        postDate: new Date(),
        postWall: 'http://netloading.com',
        postLink: '',
        phoneNumber: '0966671880',
        message: '',
        weight: '',
        status: 0,
        truckTypes: [],
        addresses: [],
      };
    } else if (id && fboffers && fboffers.length > 0) {
      fboffer = fboffers.find(item => item._id === id);

      fboffer.postWall = fboffer.postWall ? fboffer.postWall : '';
      fboffer.postLink = fboffer.postLink ? fboffer.postLink : '';
      fboffer.phoneNumber = fboffer.phoneNumber ? fboffer.phoneNumber : '';
      fboffer.weight = fboffer.weight ? fboffer.weight : '';
    }

    this.setState(fboffer);
  }

  convertToSelectDataSource = (input) => {
    const result = [];
    if (input && input.length > 0) {
      input.forEach((element) => {
        result.push({
          value: element.key,
          label: element.name,
        });
      });
    }

    return result;
  }

  convertFromSelectDataSource = (input) => {
    const result = [];
    if (input && input.length > 0) {
      input.forEach((element) => {
        result.push({
          key: element.value,
          name: element.label,
        });
      });
    }

    return result;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSelectChangeTruckTypes = (selectedOption) => {
    this.setState({
      truckTypes: selectedOption,
    });
  }

  handleSelectChangeAddresses = (selectedOption) => {
    this.setState({
      addresses: selectedOption,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      updateFbOffer, createFbOffer, history, id,
    } = this.props;

    if (id === 'NEW') {
      createFbOffer(this.state)
        .then(() => {
          window.scrollTo(0, 0);
          history.push('/fboffers');
        })
        .catch((err) => {
          this.setState({
            err: (err && err.message ? err.message : 'ERROR'),
          });
        });
    } else {
      updateFbOffer(this.state._id, this.state)
        .then(() => {
          window.scrollTo(0, 0);
          history.push('/fboffers');
        })
        .catch((err) => {
          this.setState({
            err: (err && err.message ? err.message : 'ERROR'),
          });
        });
    }
  }

  render = () => {
    const {
      loading, id, history,
    } = this.props;
    const {
      err,
      _id,
      postBy,
      message,
      weight,
      postWall,
      postLink,
      phoneNumber,
      truckTypes,
      addresses,
    } = this.state;
    const header = id === 'NEW' ? 'Tạo mới đơn hàng' : 'Cập nhật đơn hàng';

    // Loading
    if (loading) return <Loading />;

    // // Error
    // if (error) return <Error content={error} />;

    // FbOffer not found
    if (!_id && id !== 'NEW') {
      return <Error content={ErrorMessages.fboffer404} />;
    }

    return (
      <div>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                {header}
              </CardHeader>
              <CardBody>
                {!!err && (
                  <Alert color="danger">
                    {err}
                  </Alert>
                )}
                <Form onSubmit={event => this.handleSubmit(event)}>
                  <FormGroup>
                    <Label for="postBy">
                      Người tạo
                    </Label>
                    <Input
                      type="text"
                      name="postBy"
                      id="postBy"
                      placeholder=""
                      value={postBy}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="message">
                      Thông báo
                    </Label>
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      placeholder=""
                      value={message}
                      onChange={this.handleChange}
                      rows={6}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="weight">
                      Khối lượng
                    </Label>
                    <Input
                      type="text"
                      name="weight"
                      id="weight"
                      placeholder=""
                      value={weight}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="postWall">
                      Trang chủ
                    </Label>
                    <Input
                      type="text"
                      name="postWall"
                      id="postWall"
                      placeholder=""
                      value={postWall}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="postLink">
                      Liên kết
                    </Label>
                    <Input
                      type="text"
                      name="postLink"
                      id="postLink"
                      placeholder=""
                      value={postLink}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phoneNumber">
                      Điện thoại
                    </Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder=""
                      value={phoneNumber}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="truckTypes">
                      Loại xe
                    </Label>
                    <Select
                      name="truckTypes"
                      id="truckTypes"
                      value={truckTypes}
                      onChange={this.handleSelectChangeTruckTypes}
                      options={_truckTypes}
                      isMulti
                      getOptionValue={option => option.key}
                      getOptionLabel={option => option.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="addresses">
                      Tỉnh
                    </Label>
                    <Select
                      name="addresses"
                      id="addresses"
                      value={addresses}
                      onChange={this.handleSelectChangeAddresses}
                      options={_addresses}
                      isMulti
                      getOptionValue={option => option.key}
                      getOptionLabel={option => option.name}
                    />
                  </FormGroup>
                  <Button color="primary">
                    OK
                  </Button>
                  {' '}
                  <Button
                    color="secondary"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push('/fboffers');
                    }}
                  >
                    <i className="icon-arrow-left" />
                    {' '}
                    Danh sách
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FbOfferView;
