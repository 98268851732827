import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getFbOffers,
  setError,
  changeFbOfferStatus,
  updateFbOffer,
  createFbOffer,
  replaceProperty,
} from '../actions/fboffers';

const qs = require('query-string');

class FbOfferListing extends Component {
  static propTypes = {
    member: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    Layout: PropTypes.func.isRequired,
    fboffers: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      fboffers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      page: PropTypes.number,
      perPage: PropTypes.number,
      search: PropTypes.shape({}).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    getFbOffers: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    changeFbOfferStatus: PropTypes.func.isRequired,
    updateFbOffer: PropTypes.func.isRequired,
    createFbOffer: PropTypes.func.isRequired,
    replaceProperty: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: null,
    location: null,
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      dropDownOpen: false,
      dropDownToggleValue: 0,
      dropDownToggleLabel: 'Choose type',
    };
  }

  componentDidMount = () => {
    this.fetchFbOffers(1, this.props.fboffers.perPage);
  }

  componentDidUpdate(prevProps) {
    const { location, fboffers } = this.props;

    const oldPage = (prevProps.location
      && prevProps.location.search
      && qs.parse(prevProps.location.search).p);
    const newPage = (location
      && location.search
      && qs.parse(location.search).p);

    if (newPage && newPage !== oldPage) {
      this.fetchFbOffers(parseInt(newPage, 10), fboffers.perPage);
    }
  }

  onDropDownItemClick = (s) => {
    this.setState(
      {
        dropDownToggleLabel: s.currentTarget.textContent,
        dropDownToggleValue: parseInt(s.currentTarget.getAttribute('value'), 10),
      },
      () => this.fetchFbOffers(1, this.props.fboffers.perPage)
    );
  }

  fetchFbOffers = (page, perPage) => this.props.getFbOffers(
    page,
    perPage,
    this.state.dropDownToggleValue,
    this.props.fboffers.search,
  )
    .catch((err) => {
      this.props.setError(err && err.message ? err.message : 'ERROR');
    });

  toggle() {
    this.setState(prevState => ({
      dropDownOpen: !prevState.dropDownOpen,
    }));
  }

  render = () => {
    const {
      member,
      history,
      Layout,
      fboffers,
      match,
      changeFbOfferStatus,
      updateFbOffer,
      createFbOffer,
      replaceProperty,
    } = this.props;

    const id = (match && match.params && match.params.id) ? match.params.id : null;
    const { dropDownOpen, dropDownToggleLabel, dropDownToggleValue } = this.state;

    return (
      <Layout
        member={member}
        history={history}
        id={id}
        error={fboffers.error}
        loading={fboffers.loading}
        fboffers={fboffers.fboffers}
        search={fboffers.search}
        page={fboffers.page}
        perPage={fboffers.perPage}
        reFetch={() => this.fetchFbOffers(1, fboffers.perPage, dropDownToggleValue)}
        dropDownOpen={dropDownOpen}
        dropDownToggleLabel={dropDownToggleLabel}
        dropDownToggleValue={dropDownToggleValue}
        toggle={() => this.toggle()}
        onDropDownItemClick={s => this.onDropDownItemClick(s)}
        changeFbOfferStatus={(_id, status) => changeFbOfferStatus(_id, status)}
        updateFbOffer={(_id, data) => updateFbOffer(_id, data)}
        createFbOffer={data => createFbOffer(data)}
        replaceProperty={(name, data) => replaceProperty(name, data)}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  fboffers: state.fboffers || {},
});

const mapDispatchToProps = {
  getFbOffers,
  setError,
  changeFbOfferStatus,
  updateFbOffer,
  createFbOffer,
  replaceProperty,
};

export default connect(mapStateToProps, mapDispatchToProps)(FbOfferListing);
