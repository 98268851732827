import moment from 'moment';

const secondsToDate = (secs) => {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
};


const translateFromNow = date => moment(date).fromNow()
  .replace('a few seconds', 'vài giây')
  .replace('ago', 'trc')
  .replace('minutes', 'ph')
  .replace('a minute', '1 ph')
  .replace('hours', 'h')
  .replace('an hour', '1 h')
  .replace('days', 'ng')
  .replace('a day', '1 ng');

export { translateFromNow, secondsToDate };
