import moment from "moment";
import _ from "lodash";
import Store from "../store/fborderOthers";
import { translateFromNow } from "../utils/dateUtils";

export const initialState = Store;

const marshalData = (original) => {
  original.forEach((element) => {
    element.postDateFromNow = translateFromNow(element.postDate);
    element.postDateString = moment(element.postDate).format("DD/MM HH:mm");
    element.startDateString = moment(element.startDate).format("DD/MM/YYYY");
    element.endDateString = moment(element.endDate).format("DD/MM/YYYY");

    element.addresses = _.sortBy(element.addresses, ["pos"]);
  });

  return original;
};

export default function fborderOtherReducer(state = initialState, action) {
  switch (action.type) {
    case "FBORDER_OTHERS_LOADING": {
      return {
        ...state,
        loading: action.data,
      };
    }
    case "FBORDER_OTHERS_ERROR": {
      return {
        ...state,
        error: action.data,
      };
    }
    case "FBORDER_OTHERS_REPLACE": {
      const fborders = marshalData(action.data);

      return {
        ...state,
        error: null,
        loading: false,
        fborders,
      };
    }
    default:
      return state;
  }
}
