const truckTypes = [
  {
    name: "1 tấn",
    key: "1",
  },
  {
    name: "1.4 tấn",
    key: "1.4",
  },
  {
    name: "1.9 tấn",
    key: "1.9",
  },
  {
    name: "2.5 tấn",
    key: "2.5",
  },
  {
    name: "3.5 tấn",
    key: "3.5",
  },
  {
    name: "5 tấn",
    key: "5",
  },
  {
    name: "7 tấn",
    key: "7",
  },
  {
    name: "8 tấn",
    key: "8",
  },
  {
    name: "10 tấn",
    key: "10",
  },
  {
    name: "15 tấn",
    key: "15",
  },
  {
    name: "18 tấn",
    key: "18",
  },
  {
    name: "22 tấn",
    key: "22",
  },
  {
    name: "25 tấn",
    key: "25",
  },
  {
    name: "28 tấn",
    key: "28",
  },
  {
    name: "33 tấn",
    key: "33",
  },
  {
    name: "33+ tấn",
    key: "33+",
  },
];

const addresses = [
  { key: "angiang", name: "An Giang" },
  { key: "bariavungtau", name: "Bà Rịa Vũng Tàu" },
  { key: "bacgiang", name: "Bắc Giang" },
  { key: "backan", name: "Bắc Kạn" },
  { key: "baclieu", name: "Bạc Liêu" },
  { key: "bacninh", name: "Bắc Ninh" },
  { key: "bentre", name: "Bến Tre" },
  { key: "binhdinh", name: "Bình Định" },
  { key: "binhduong", name: "Bình Dương" },
  { key: "binhphuoc", name: "Bình Phước" },
  { key: "binhthuan", name: "Bình Thuận" },
  { key: "camau", name: "Cà Mau" },
  { key: "cantho", name: "Cần Thơ" },
  { key: "caobang", name: "Cao Bằng" },
  { key: "danang", name: "Đà Nẵng" },
  { key: "daclak", name: "Đắk Lắk" },
  { key: "daknong", name: "Đắk Nông" },
  { key: "dienbien", name: "Điện Biên" },
  { key: "dongnai", name: "Đồng Nai" },
  { key: "dongthap", name: "Đồng Tháp" },
  { key: "gialai", name: "Gia Lai" },
  { key: "hagiang", name: "Hà Giang" },
  { key: "hanam", name: "Hà Nam" },
  { key: "hanoi", name: "Hà Nội" },
  { key: "hatinh", name: "Hà Tĩnh" },
  { key: "haiduong", name: "Hải Dương" },
  { key: "haiphong", name: "Hải Phòng" },
  { key: "haugiang", name: "Hậu Giang" },
  { key: "hochiminh", name: "Hồ Chí Minh" },
  { key: "hoabinh", name: "Hoà Bình" },
  { key: "hungyen", name: "Hưng Yên" },
  { key: "khanhhoa", name: "Khánh Hòa" },
  { key: "kiengiang", name: "Kiên Giang" },
  { key: "kontum", name: "Kon Tum" },
  { key: "laichau", name: "Lai Châu" },
  { key: "lamdong", name: "Lâm Đồng" },
  { key: "langson", name: "Lạng Sơn" },
  { key: "laocai", name: "Lào Cai" },
  { key: "longan", name: "Long An" },
  { key: "namdinh", name: "Nam Định" },
  { key: "nghean", name: "Nghệ An" },
  { key: "ninhbinh", name: "Ninh Bình" },
  { key: "ninhthuan", name: "Ninh Thuận" },
  { key: "phutho", name: "Phú Thọ" },
  { key: "phuyen", name: "Phú Yên" },
  { key: "quangbinh", name: "Quảng Bình" },
  { key: "quangnam", name: "Quảng Nam" },
  { key: "quangngai", name: "Quảng Ngãi" },
  { key: "quangninh", name: "Quảng Ninh" },
  { key: "quangtri", name: "Quảng Trị" },
  { key: "soctrang", name: "Sóc Trăng" },
  { key: "sonla", name: "Sơn La" },
  { key: "tayninh", name: "Tây Ninh" },
  { key: "thaibinh", name: "Thái Bình" },
  { key: "thainguyen", name: "Thái Nguyên" },
  { key: "thanhhoa", name: "Thanh Hóa" },
  { key: "thuathienhue", name: "Thừa Thiên Huế" },
  { key: "tiengiang", name: "Tiền Giang" },
  { key: "travinh", name: "Trà Vinh" },
  { key: "tuyenquang", name: "Tuyên Quang" },
  { key: "vinhlong", name: "Vĩnh Long" },
  { key: "vinhphuc", name: "Vĩnh Phúc" },
  { key: "yenbai", name: "Yên Bái" },
  { key: "trungquoc", name: "TRUNG QUỐC" },
  { key: "lao", name: "LÀO" },
  { key: "campuchia", name: "CAMPUCHIA" },
  { key: "myanmar", name: "MYANMAR" },
  { key: "thailan", name: "THÁI LAN" },
];

const weightTypes = [
  { key: "kg", name: "Kg" },
  { key: "tan", name: "Tấn" },
  { key: "thung", name: "Thùng" },
  { key: "khoi", name: "Khối" },
  { key: "kien", name: "Kiện" },
];

// Used for data of old Android apps and CMS1
const weightTypes2 = [
  { key: 1, name: "Kg", proportion: 1 },
  { key: 2, name: "Tấn", proportion: 1000 },
];

const truckKinds = [
  { key: 1, name: "Xe tải" },
  { key: 2, name: "Xe đầu kéo" },
  { key: 3, name: "Xe container" },
  { key: 4, name: "Xe đông lạnh" },
  { key: 5, name: "Xe bồn" },
  { key: 6, name: "Xe ben" },
  { key: 7, name: "Xe cẩu" },
  { key: 8, name: "Xe chuyên dụng khác" },
];

const cities = [
  { key: 1, name: "An Giang", gps: "10.503183,105.119829" },
  { key: 2, name: "Bà Rịa - Vũng Tàu", gps: "10.563561,107.276515" },
  { key: 3, name: "Bắc Giang", gps: "21.362262,106.176664" },
  { key: 4, name: "Bắc Kạn", gps: "22.240912,105.819391" },
  { key: 5, name: "Bạc Liêu", gps: "9.291818,105.467610" },
  { key: 6, name: "Bắc Ninh", gps: "21.136271,106.083659" },
  { key: 7, name: "Bến Tre", gps: "10.137806,106.565089" },
  { key: 8, name: "Bình Định", gps: "14.169255,108.899803" },
  { key: 9, name: "Bình Dương", gps: "11.207645,106.578304" },
  { key: 10, name: "Bình Phước", gps: "11.680601,106.825329" },
  { key: 11, name: "Bình Thuận", gps: "10.947979,107.826546" },
  { key: 12, name: "Cà Mau", gps: "8.820449,105.084218" },
  { key: 13, name: "Cao Bằng", gps: "22.803639,105.711757" },
  { key: 14, name: "Đắk Lắk", gps: "12.865012,108.003386" },
  { key: 15, name: "Đắk Nông", gps: "12.107715,107.830821" },
  { key: 16, name: "Điện Biên", gps: "21.752904,103.101496" },
  { key: 17, name: "Đồng Nai", gps: "10.714318,107.005192" },
  { key: 18, name: "Đồng Tháp", gps: "10.581382,105.688341" },
  { key: 19, name: "Gia Lai", gps: "13.870961,108.282026" },
  { key: 20, name: "Hà Giang", gps: "22.522165,104.765151" },
  { key: 21, name: "Hà Nam", gps: "20.447984,105.919875" },
  { key: 22, name: "Hà Tĩnh", gps: "18.264984,105.696628" },
  { key: 23, name: "Hải Dương", gps: "20.781788,106.289502" },
  { key: 24, name: "Hậu Giang", gps: "9.790570,105.576663" },
  { key: 25, name: "Hòa Bình", gps: "20.730707,105.474031" },
  { key: 26, name: "Hưng Yên", gps: "20.833808,106.010205" },
  { key: 27, name: "Khánh Hòa", gps: "12.307491,108.871089" },
  { key: 28, name: "Kiên Giang", gps: "10.009664,105.197401" },
  { key: 29, name: "Kon Tum", gps: "14.313554,107.920630" },
  { key: 30, name: "Lai Châu", gps: "22.324320,102.843735" },
  { key: 31, name: "Lâm Đồng", gps: "11.641015,107.497746" },
  { key: 32, name: "Lạng Sơn", gps: "21.820741,106.547897" },
  { key: 33, name: "Lào Cai", gps: "22.513893,103.856868" },
  { key: 34, name: "Long An", gps: "10.695005,105.960820" },
  { key: 35, name: "Nam Định", gps: "20.413892,106.162605" },
  { key: 36, name: "Nghệ An", gps: "19.395257,104.889498" },
  { key: 37, name: "Ninh Bình", gps: "20.247371,105.970816" },
  { key: 38, name: "Ninh Thuận", gps: "11.708233,108.886415" },
  { key: 39, name: "Phú Thọ", gps: "21.307744,105.125561" },
  { key: 40, name: "Quảng Bình", gps: "17.650996,106.225225" },
  { key: 41, name: "Quảng Nam", gps: "15.582603,107.985250" },
  { key: 42, name: "Quảng Ngãi", gps: "15.033132,108.631560" },
  { key: 43, name: "Quảng Ninh", gps: "21.151562,107.203928" },
  { key: 44, name: "Quảng Trị", gps: "16.725876,107.103291" },
  { key: 45, name: "Sóc Trăng", gps: "9.605473,105.973519" },
  { key: 46, name: "Sơn La", gps: "9.600734,105.978154" },
  { key: 47, name: "Tây Ninh", gps: "11.357658,106.130780" },
  { key: 48, name: "Thái Bình", gps: "20.506917,106.374343" },
  { key: 49, name: "Thái Nguyên", gps: "21.587135,105.824038" },
  { key: 50, name: "Thanh Hóa", gps: "20.091208,105.301704" },
  { key: 51, name: "Thừa Thiên Huế", gps: "16.344361,107.581729" },
  { key: 52, name: "Tiền Giang", gps: "10.438860,106.253686" },
  { key: 53, name: "Trà Vinh", gps: "9.934929,106.336861" },
  { key: 54, name: "Tuyên Quang", gps: "22.058060,105.244858" },
  { key: 55, name: "Vĩnh Long", gps: "10.244966,105.958561" },
  { key: 56, name: "Vĩnh Phúc", gps: "21.301371,105.591167" },
  { key: 57, name: "Yên Bái", gps: "21.718615,104.929472" },
  { key: 58, name: "Phú Yên", gps: "13.096341,109.292911" },
  { key: 59, name: "Cần Thơ", gps: "10.044264,105.748773" },
  { key: 60, name: "Đà Nẵng", gps: "16.053866,108.203836" },
  { key: 61, name: "Hải Phòng", gps: "20.843685,106.694454" },
  { key: 62, name: "Hà Nội", gps: "21.025905,105.846576" },
  { key: 63, name: "TP Hồ Chí Minh", gps: "10.820645,106.632518" },
];

const licensePeriods = [
  { key: 1, name: "1 tháng" },
  { key: 3, name: "3 tháng" },
  { key: 6, name: "6 tháng" },
  { key: 12, name: "12 tháng" },
  { key: 99, name: "~ tháng" },
];

const replacementsCommodity = [
  { keyword: "ghép", replacement: "bốc" },
  { keyword: "ghép", replacement: "bốc" },
  { keyword: "ghep", replacement: "bốc" },
  { keyword: "gép", replacement: "bốc" },
  { keyword: "gep", replacement: "bốc" },
  { keyword: "ké", replacement: "bốc" }, // # kéo
  { keyword: "tiện chuyến", replacement: "🚚" },
  { keyword: "tien chuyen", replacement: "🚚" },
  { keyword: "chiều về", replacement: "🚚" },
  { keyword: "chieu ve", replacement: "🚚" },
  { keyword: "quay đầu", replacement: "🚚" },
  { keyword: "quay dau", replacement: "🚚" },
  // { keyword: "nào", replacement: "" },
  { keyword: "này", replacement: "" },
  { keyword: "ít hàng", replacement: "hàng nhẹ" },
  { keyword: "ít đồ", replacement: "hàng nhẹ" },
  { keyword: "… xem thêm", replacement: "" },
  { keyword: "hỏng", replacement: "" },
  { keyword: "cũ", replacement: "" },
  { keyword: "phế", replacement: "" },
  { keyword: "vụn", replacement: "" },
];

const OrderStatus = {
  ORDER_NEED_APPROVAL: 0,
  ORDER_DRAFTED_BY_OWNER: -11,
  ORDER_POTENTIAL_APPROVED: -1,
  ORDER_POTENTIAL_REJECTED: -2,
  ORDER_POTENTIAL_TRUCK_OWNER: -3,
  ORDER_APPROVED: 1,
  ORDER_REJECTED: 2,
  ORDER_TRUCK_OWNER: 3,
  ORDER_IGNORED: 11,
  GUIDE: 5,
  GUIDE_HIDDEN: -5,
  LICENSE_NEW: 51,
  LICENSE_WARNING: 52,
};

const OrderStatusDetector = {
  ORDER_SET_STATUS_BY_HUMAN: 0,
  ORDER_POSTED_BY_ADMIN: 122,
  ORDER_POSTED_BY_OWNER: 123,
  ORDER_POSTED_BY_OWNER_THN2: 124,
};

// const OrderStatusDefault = -102;
const OrderStatusDefault = 0;
// const OrderStatusDefault = -1;

module.exports = Object.freeze({
  truckTypes,
  addresses,
  truckKinds,
  cities,
  weightTypes,
  weightTypes2,
  licensePeriods,
  replacementsCommodity,
  OrderStatus,
  OrderStatusDetector,
  OrderStatusDefault,
});
