import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getMembersForLicenseFunction,
  addMemberLicense,
} from '../actions/member';

class MemberLicenseListing extends Component {
  static propTypes = {
    roleAdmin: PropTypes.bool,
    roleSale: PropTypes.bool,
    Layout: PropTypes.func.isRequired,
    memberLicenses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    _getMembersForLicenseFunction: PropTypes.func.isRequired,
    _addMemberLicense: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }

  static defaultProps = {
    match: null,
    location: null,
    roleAdmin: false,
    roleSale: false,
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render = () => {
    const {
      Layout,
      roleAdmin, roleSale,
      memberLicenses,
      _getMembersForLicenseFunction,
      _addMemberLicense,
    } = this.props;
    return (
      <Layout
        roleAdmin={roleAdmin}
        roleSale={roleSale}
        members={memberLicenses}
        getMembersForLicenseFunction={_getMembersForLicenseFunction}
        addMemberLicense={_addMemberLicense}
      />
    );
  }
}

const mapStateToProps = state => ({
  memberLicenses: state.member.memberLicenses || [],
  roleAdmin: state.member.roleAdmin || false,
  roleSale: state.member.roleSale || false,
});

const mapDispatchToProps = {
  _getMembersForLicenseFunction: getMembersForLicenseFunction,
  _addMemberLicense: addMemberLicense,
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberLicenseListing);
