import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCustomersNoPaging, setError, updateCustomer,
} from '../actions/customers';

class CustomerListing extends Component {
  static propTypes = {
    roleAdmin: PropTypes.bool,
    Layout: PropTypes.func.isRequired,
    customers: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      customers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      page: PropTypes.number,
      perPage: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    getCustomersNoPaging: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    updateCustomer: PropTypes.func.isRequired,
  }

  static defaultProps = {
    roleAdmin: false,
    match: null,
    location: null,
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {
    this.fetchCustomers();
  }

  fetchCustomers = () => this.props.getCustomersNoPaging()
    .catch((err) => {
      this.props.setError(err && err.message ? err.message : 'ERROR');
    });

  render = () => {
    const {
      roleAdmin, history, Layout, customers, match, updateCustomer,
    } = this.props;
    const id = (match && match.params && match.params.id) ? match.params.id : null;

    return (
      <Layout
        roleAdmin={roleAdmin}
        history={history}
        id={id}
        error={customers.error}
        loading={customers.loading}
        customers={customers.customers}
        page={customers.page}
        perPage={customers.perPage}
        reFetch={() => this.fetchCustomers()}
        updateCustomer={(id, data) => updateCustomer(id, data)}
      />
    );
  }
}

const mapStateToProps = state => ({
  customers: state.customers || {},
  roleAdmin: state.member.roleAdmin || false,
});

const mapDispatchToProps = {
  getCustomersNoPaging,
  updateCustomer,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListing);
