import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Row, Col, Alert } from "reactstrap";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";
import Loading from "./Loading";

const CaremptyListing = ({
  member,
  error,
  loading,
  carempties,
  page,
  perPage,
  reFetch,
}) => {
  if (loading) return <Loading />;

  if (!member.roleSale && !member.roleSalemng && !member.roleAdmin) {
    return <Redirect to="/" />;
  }

  const data = carempties;

  // // Error
  // if (error) return <Error content={error} />;

  // Show Listing
  return (
    <div style={{ height: "100vh", width: "99.9%" }}>
      <Row>
        <Col>
          {/* <h1>
            Xe chiều về
          </h1> */}
        </Col>
      </Row>
      <Row className={loading ? "content-loading" : ""}>
        <Col sm="12">
          {!!error && <Alert color="danger">{error}</Alert>}
          <ReactTable
            data={data}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                Header: "Họ tên",
                id: "fullName",
                accessor: (d) => d.fullName,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["fullName"] }),
                filterAll: true,
              },
              {
                Header: "Số ĐT",
                id: "fone",
                accessor: (d) => d.fone,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["fone"] }),
                filterAll: true,
              },
              {
                Header: "Số xe",
                id: "numberCar",
                accessor: (d) => d.numberCar,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["numberCar"] }),
                filterAll: true,
              },
              {
                Header: "Trọng tải",
                id: "weight",
                accessor: (d) => d.weight,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["weight"] }),
                filterAll: true,
              },
              {
                Header: "Đi",
                id: "cityStartString",
                accessor: (d) => d.cityStartString,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["cityStartString"],
                  }),
                filterAll: true,
              },
              {
                Header: "Đến",
                id: "cityEndString",
                accessor: (d) => d.cityEndString,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["cityEndString"] }),
                filterAll: true,
              },
              {
                Header: "Ngày đóng",
                id: "timeStartString",
                accessor: (d) => d.timeStartString,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["timeStartString"],
                  }),
                filterAll: true,
              },
              {
                Header: "Giá",
                id: "priceString",
                accessor: (d) => d.priceString,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["priceString"] }),
                filterAll: true,
              },

              {
                Header: "Nội dung",
                id: "note",
                accessor: (d) => d.note,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["note"] }),
                filterAll: true,
              },
            ]}
            defaultPageSize={10}
            className="-striped -highlight"
            previousText="Trước"
            nextText="Sau"
            loadingText="Dữ liệu đang được tải..."
            noDataText="Không tồn tại dữ liệu"
            pageText="Trang"
            ofText="của"
            rowsText="dòng"
          />
        </Col>
      </Row>
    </div>
  );
};

CaremptyListing.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  member: PropTypes.shape({}).isRequired,
  carempties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  reFetch: PropTypes.func.isRequired,
};

CaremptyListing.defaultProps = {
  error: null,
};

export default CaremptyListing;
