import axios from "axios";
import AppConfig from "../constants/config";
import { getReqConfig } from "../utils/authUtils";

function setLoading(dispatch, loading) {
  return Promise.resolve(
    dispatch({
      type: "FBOFFERS_LOADING",
      data: loading,
    })
  );
}

/**
 * Set an Error Message
 */
export function setError(message) {
  return (dispatch) =>
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: "FBOFFERS_ERROR",
          data: message,
        })
      )
    );
}

function getErrorObject(err) {
  if (err && err.response && err.response.data && err.response.data) {
    return err.response.data;
  }
  return err;
}

/**
 * Get FbOffers
 */
export function getFbOffers(page, perPage, status, search) {
  let tmpAddressStart = "";
  if (search && search.addressesStart && search.addressesStart.length > 0) {
    search.addressesStart.forEach((element, index) => {
      tmpAddressStart += element.key;
      if (index < search.addressesStart.length - 1) {
        tmpAddressStart += ",";
      }
    });
  }

  let tmpAddressEnd = "";
  if (search && search.addressesEnd && search.addressesEnd.length > 0) {
    search.addressesEnd.forEach((element, index) => {
      tmpAddressEnd += element.key;
      if (index < search.addressesEnd.length - 1) {
        tmpAddressEnd += ",";
      }
    });
  }

  const tmpPostBy = search.postBy ? search.postBy : "";

  const tmpTextSearch = search.content ? `&t=${search.content}` : "";

  const api = `${AppConfig.backendApi}/fbOffers?page=${page}&perPage=${perPage}&status=${status}&ad=${tmpAddressStart}&ade=${tmpAddressEnd}&pb=${tmpPostBy}${tmpTextSearch}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      await setLoading(dispatch, true);

      if (page <= 0) {
        return resolve(await setLoading(dispatch, false));
      }

      const headers = await getReqConfig();
      return axios
        .get(api, { headers })
        .then(async (response) => {
          const { data } = response;

          await setLoading(dispatch, false);

          if (page === 1) {
            return resolve(
              dispatch({
                type: "FBOFFERS_REPLACE",
                data: data || [],
                page,
                perPage,
              })
            );
          }

          if (!data || data.length <= 0) {
            return resolve();
          }

          return resolve(
            dispatch({
              type: "FBOFFERS_REPLACE",
              data,
              page,
              perPage,
            })
          );
        })
        .catch(async (err) => {
          await setLoading(dispatch, false);

          reject(getErrorObject(err));
        });
    });
}

export function changeFbOfferStatus(id, status) {
  const data = {
    status,
  };

  const api = `${AppConfig.backendApi}/fbOffers/${id}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();
      axios
        .patch(api, data, { headers })
        .then((response) => {
          resolve(
            dispatch({
              type: "FBOFFERS_REMOVE",
              data: id,
            })
          );
        })
        .catch((err) => {
          reject(getErrorObject(err));
        });
    });
}

export function updateFbOffer(id, data) {
  const api = `${AppConfig.backendApi}/fbOffers/${id}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();
      axios
        .patch(api, data, { headers })
        .then((response) => {
          resolve(
            dispatch({
              type: "FBOFFERS_UPDATE",
              data: response.data,
            })
          );
        })
        .catch((err) => {
          reject(getErrorObject(err));
        });
    });
}

export function createFbOffer(data) {
  const api = `${AppConfig.backendApi}/fbOffers`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();
      axios
        .post(api, data, { headers })
        .then((response) => {
          resolve(
            dispatch({
              type: "FBOFFERS_ADD",
              data: response.data,
            })
          );
        })
        .catch((err) => {
          reject(getErrorObject(err));
        });
    });
}

export function replaceProperty(name, data) {
  return (dispatch) =>
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: "FBOFFERS_PROPERTY_REPLACE",
          name,
          data,
        })
      )
    ).catch((e) => console.log(e));
}
