import axios from "axios";
import AppConfig from "../constants/config";
import { getReqConfig } from "../utils/authUtils";
import { OrderStatusDetector } from "../constants/constants";

function setLoading(dispatch, loading) {
  return Promise.resolve(
    dispatch({
      type: "FBORDERS_LOADING",
      data: loading,
    })
  );
}

/**
 * Set an Error Message
 */
export function setError(message) {
  return (dispatch) =>
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: "FBORDERS_ERROR",
          data: message,
        })
      )
    );
}

function getErrorObject(err) {
  if (err?.response?.data) {
    return err.response.data;
  }
  return err;
}

/**
 * Get FbOrders
 */
export function getFbOrders(page, perPage, status, search) {
  let tmpAddressStart = "";
  if (search && search.addressesStart && search.addressesStart.length > 0) {
    search.addressesStart.forEach((element, index) => {
      tmpAddressStart += element.key;
      if (index < search.addressesStart.length - 1) {
        tmpAddressStart += ",";
      }
    });
  }

  let tmpAddressEnd = "";
  if (search && search.addressesEnd && search.addressesEnd.length > 0) {
    search.addressesEnd.forEach((element, index) => {
      tmpAddressEnd += element.key;
      if (index < search.addressesEnd.length - 1) {
        tmpAddressEnd += ",";
      }
    });
  }

  const tmpPostBy = search.postBy ? `&pb=${search.postBy}` : "";
  const tmpPhoneNumber = search.phoneNumber
    ? `&fone=${search.phoneNumber}`
    : "";

  const tmpTextSearch = search.content ? `&t=${search.content}` : "";

  const api = `${AppConfig.backendApi}/fbOrders?page=${page}&perPage=${perPage}&status=${status}&ad=${tmpAddressStart}&ade=${tmpAddressEnd}${tmpPostBy}${tmpPhoneNumber}${tmpTextSearch}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      await setLoading(dispatch, true);

      if (page <= 0) {
        return resolve(await setLoading(dispatch, false));
      }

      const headers = await getReqConfig();

      fetch(api, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.ok) return response.json();
          else
            throw { status: response.status, statusText: response.statusText };
        })
        .then(async (responseJson) => {
          const data = responseJson;

          await setLoading(dispatch, false);

          if (page === 1) {
            return resolve(
              dispatch({
                type: "FBORDERS_REPLACE",
                data: data || [],
                page,
                perPage,
              })
            );
          }

          if (!data || data.length <= 0) {
            return resolve();
          }

          return resolve(
            dispatch({
              type: "FBORDERS_REPLACE",
              data,
              page,
              perPage,
            })
          );
        })
        .catch(async (err) => {
          await setLoading(dispatch, false);
          console.error(err);
          reject(getErrorObject(err));
        });
    });
}

export function changeFbOrderStatus(id, status, addresses, commodity) {
  const data = {
    status,
    statusDetector: OrderStatusDetector.ORDER_SET_STATUS_BY_HUMAN,
    statusDateNeedToUpdate: true,
    addresses,
    commodity,
  };

  const api = `${AppConfig.backendApi}/fbOrders/${id}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();
      axios
        .patch(api, data, { headers })
        .then((/* response */) => {
          resolve(
            dispatch({
              type: "FBORDERS_REMOVE",
              data: id,
            })
          );
        })
        .catch((err) => {
          console.log(
            `changeFbOrderStatus ### ${JSON.stringify(err, null, 2)}`
          );
          reject(getErrorObject(err));
        });
    });
}

export function updateFbOrder(id, data) {
  const api = `${AppConfig.backendApi}/fbOrders/${id}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();
      axios
        .patch(api, data, { headers })
        .then((response) => {
          resolve(
            dispatch({
              type: "FBORDERS_UPDATE",
              data: response.data,
            })
          );
        })
        .catch((err) => {
          console.log(`updateFbOrder ### ${JSON.stringify(err, null, 2)}`);
          reject(getErrorObject(err));
        });
    });
}

export function createFbOrder(data) {
  const api = `${AppConfig.backendApi}/fbOrders`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      const headers = await getReqConfig();
      axios
        .post(api, data, { headers })
        .then((response) => {
          resolve(
            dispatch({
              type: "FBORDERS_ADD",
              data: response.data,
            })
          );
        })
        .catch((err) => {
          reject(getErrorObject(err));
        });
    });
}
export function replaceProperty(name, data) {
  return (dispatch) =>
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: "FBORDERS_PROPERTY_REPLACE",
          name,
          data,
        })
      )
    ).catch((e) => console.log(e));
}
