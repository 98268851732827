import React from "react";
import { Col, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";

const SidebarNavItems = (member) => (
  <div>
    <NavItem
      hidden={!member.roleAdmin && !member.roleSalemng && !member.roleSale}
    >
      <Link
        className={`nav-link ${
          (window.location.pathname === "/" ||
            window.location.pathname.startsWith("/fborder")) &&
          "active"
        }`}
        to="/"
      >
        <i className="icon-diamond" /> <span>HTX</span>
      </Link>
    </NavItem>
    {/* <NavItem hidden={!member.roleAdmin && !member.roleSalemng}>
      <Link
        className={`nav-link ${
          window.location.pathname === "/mapview" && "active"
        }`}
        to="/mapview"
      >
        <i className="icon-map" /> <span>Mapview</span>
      </Link>
    </NavItem> */}
    {/* <NavItem hidden={!member.roleAdmin && !member.roleSalemng && !member.roleSale}>
      <Link className={`nav-link ${window.location.pathname.startsWith('/temp') && 'active'}`} to="/temp">
        <i className="icon-notebook" />
        {' '}
        <span>
          Temp
        </span>
      </Link>
    </NavItem> */}
    <NavItem hidden={!member.roleAdmin}>
      <Link
        className={`nav-link ${
          window.location.pathname.startsWith("/customer") && "active"
        }`}
        to="/customers"
      >
        <i className="icon-people" /> <span>User</span>
      </Link>
    </NavItem>
    <NavItem
      hidden={!member.roleAdmin && !member.roleSalemng && !member.roleSale}
    >
      <Link
        className={`nav-link ${
          window.location.pathname.startsWith("/carempties") && "active"
        }`}
        to="/carempties"
      >
        <i className="icon-magnet" /> <span>Get Back</span>
      </Link>
    </NavItem>
    <NavItem
      hidden={!member.roleAdmin && !member.roleSalemng && !member.roleSale}
    >
      <Link
        className={`nav-link ${
          window.location.pathname.startsWith("/requests") && "active"
        }`}
        to="/requests"
      >
        <i className="icon-shuffle" /> <span>Quotation</span>
      </Link>
    </NavItem>
    <NavItem hidden={!member.roleAdmin}>
      <Link
        className={`nav-link ${
          window.location.pathname.startsWith("/licenses") && "active"
        }`}
        to="/licenses"
      >
        <i className="icon-key" /> <span>Account</span>
      </Link>
    </NavItem>
    <NavItem
      hidden={!member.roleAdmin && !member.roleSalemng && !member.roleSale}
    >
      <Link
        className={`nav-link ${
          window.location.pathname.startsWith("/fboffer") && "active"
        }`}
        to="/fboffers"
      >
        <i className="icon-diamond" /> <span>XTH</span>
      </Link>
    </NavItem>
  </div>
);

const Sidebar = ({ member }) => {
  if (
    !member ||
    (!member.roleAdmin && !member.roleSalemng && !member.roleSale)
  ) {
    return <></>;
  } else {
    return (
      <div>
        <Col sm="3" md="2" className="d-none d-sm-block sidebar">
          <Nav vertical>{SidebarNavItems(member)}</Nav>
        </Col>
      </div>
    );
  }
};

export { Sidebar, SidebarNavItems };
