// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: true,
  error: null,
  fborders: [],
  page: 1,
  perPage: 50,
  search: {
    postBy: "",
    truckTypes: [],
    phoneNumber: "",
    content: "",
    addressesStart: [],
    addressesEnd: [],
  },
};
