import AppConfig from "../constants/config";
import { getReqConfig } from "../utils/authUtils";

function setLoading(dispatch, loading) {
  return Promise.resolve(
    dispatch({
      type: "FBORDER_OTHERS_LOADING",
      data: loading,
    })
  );
}

export function setError(message) {
  return (dispatch) =>
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: "FBORDER_OTHERS_ERROR",
          data: message,
        })
      )
    );
}

function getErrorObject(err) {
  if (err?.response?.data) {
    return err.response.data;
  }
  return err;
}

export function getOtherFbOrdersByOwnerInfo(nid, wall, fones) {
  const api = `${AppConfig.backendApi}/fbOrders/listOthersByOwnerInfo?page=1&perPage=50&nid=${nid}&wall=${wall}&fones=${fones}`;

  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        await setLoading(dispatch, true);

        const headers = await getReqConfig();
        const response = await fetch(api, {
          method: "GET",
          headers,
        });
        if (!response.ok) {
          throw { status: response.status, statusText: response.statusText };
        }

        const data = await response.json();

        await setLoading(dispatch, false);
        resolve(
          dispatch({
            type: "FBORDER_OTHERS_REPLACE",
            data,
          })
        );
      } catch (error) {
        await setLoading(dispatch, false);
        console.error(error);
        reject(getErrorObject(error));
      }
    });
}
