import status from "./status";
import member from "./member";
import locale from "./locale";
import fborders from "./fborders";
import fborderOthers from "./fborderOthers";
import fboffers from "./fboffers";
import customers from "./customers";
import map from "./map";
import carempties from "./carempties";
import requests from "./requests";

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case "persist/REHYDRATE":
      return true;
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  rehydrated,
  status,
  member,
  locale,
  fborders,
  fborderOthers,
  fboffers,
  customers,
  map,
  carempties,
  requests,
};
