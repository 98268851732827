import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from 'reactstrap';
import Select from 'react-select';
import ErrorMessages from '../../constants/errors';
import Loading from './Loading';
import Error from './Error';

const optionsStatus = [
  { value: 'active', label: 'Kích hoạt' },
  { value: '', label: 'Chờ kiểm duyệt/Ngừng kích hoạt' },
];

class CustomerView extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    customers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    updateCustomer: PropTypes.func.isRequired,
  }

  static defaultProps = {
    error: null,
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentWillMount() {
    const { id, customers } = this.props;

    let customer = null;
    if (id === 'NEW') {
      customer = {
        // postBy: 'NetLoading',
        // postDate: new Date(),
        // postWall: 'http://netloading.com.vn',
        // postLink: '',
        // phoneNumber: '0966671880',
        // message: '',
        // weight: '',
        // status: 0,
        // tmpTruckTypes: [],
        // tmpAddresses: [],
      };
    } else if (id && customers && customers.length > 0) {
      customer = customers.find(item => item._id === id);

      customer.fullName = customer.fullName ? customer.fullName : '';
      customer.email = customer.email ? customer.email : '';
      customer.fone = customer.fone ? customer.fone : '';
      customer.status = customer.status ? customer.status : '';
      customer.company = customer.company ? customer.company : {
        address: '',
        gps: '',
      };

      customer.tmpStatus = this.getSelectedOptionFromValue(customer.status, optionsStatus);
    }

    this.setState(customer);
  }

  getSelectedOptionFromValue = (value, options) => {
    let result;
    options.forEach((ele) => {
      if (ele.value === value) {
        result = ele;
      }
      return null;
    });

    return result;
  }

  convertToSelectDataSource = (input) => {
    const result = [];
    if (input && input.length > 0) {
      input.forEach((element) => {
        result.push({
          value: element.key,
          label: element.name,
        });
      });
    }

    return result;
  }

  convertFromSelectDataSource = (input) => {
    const result = [];
    if (input && input.length > 0) {
      input.forEach((element) => {
        result.push({
          key: element.value,
          name: element.label,
        });
      });
    }

    return result;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleChangeCompany = (event) => {
    const { company } = this.state;
    const tmpCompany = company;
    tmpCompany[event.target.name] = event.target.value;
    this.setState({
      company: tmpCompany,
    });
  }

  handleSelectChangeStatus = (selectedOption) => {
    this.setState({
      tmpStatus: selectedOption,
      status: selectedOption.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      updateCustomer, history, id,
    } = this.props;

    if (id === 'NEW') {
      // createCustomer(this.state)
      //   .then(() => {
      //     window.scrollTo(0, 0);
      //     history.push('/customers');
      //   })
      //   .catch((err) => {
      //     this.setState({
      //       err: (err && err.message ? err.message : 'ERROR'),
      //     });
      //   });
    } else {
      updateCustomer(this.state._id, this.state)
        .then(() => {
          window.scrollTo(0, 0);
          history.push('/customers');
        })
        .catch((err) => {
          this.setState({
            err: (err && err.message ? err.message : 'ERROR'),
          });
        });
    }
  }

  render = () => {
    const {
      loading, id, history,
    } = this.props;
    const {
      err,
      _id,
      fullName,
      email,
      fone,
      company,
      tmpStatus,
    } = this.state;
    const header = id === 'NEW' ? 'Tạo mới người dùng' : 'Cập nhật người dùng';

    // Loading
    if (loading) return <Loading />;

    // // Error
    // if (error) return <Error content={error} />;

    // Customer not found
    if (!_id && id !== 'NEW') {
      return <Error content={ErrorMessages.customer404} />;
    }

    return (
      <div>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                {header}
              </CardHeader>
              <CardBody>
                {!!err && (
                  <Alert color="danger">
                    {err}
                  </Alert>
                )}
                <Form onSubmit={event => this.handleSubmit(event)}>
                  <FormGroup>
                    <Label for="fullName">
                      Họ tên
                    </Label>
                    <Input
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder=""
                      value={fullName}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">
                      Email
                    </Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={this.handleChange}
                      rows={6}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="fone">
                      Điện thoại
                    </Label>
                    <Input
                      type="text"
                      name="fone"
                      id="fone"
                      placeholder=""
                      value={fone}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">
                      Trạng thái
                    </Label>
                    <Select
                      name="status"
                      id="status"
                      value={tmpStatus}
                      onChange={this.handleSelectChangeStatus}
                      options={optionsStatus}
                      isMulti={false}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="address">
                      Địa chỉ
                    </Label>
                    <Input
                      type="text"
                      name="address"
                      id="address"
                      placeholder=""
                      value={company.address}
                      onChange={this.handleChangeCompany}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="GPS">
                      Vị trí
                    </Label>
                    <Input
                      type="text"
                      name="gps"
                      id="gps"
                      placeholder=""
                      value={company.gps}
                      onChange={this.handleChangeCompany}
                    />
                  </FormGroup>

                  <Button color="primary">
                    OK
                  </Button>
                  {' '}
                  <Button
                    color="secondary"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push('/customers');
                    }}
                  >
                    <i className="icon-arrow-left" />
                    {' '}
                    Danh sách
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CustomerView;
