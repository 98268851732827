import AppConfig from "../constants/config";

export async function triggerRefreshToken() {
  let savedToken = localStorage.getItem("token");
  if (savedToken) {
    savedToken = JSON.parse(savedToken);
    savedToken.expiresIn = new Date();
    localStorage.setItem("token", JSON.stringify(savedToken));
  }
}

export async function getReqConfig() {
  let savedToken = localStorage.getItem("token");

  if (savedToken) {
    savedToken = JSON.parse(savedToken);
    const expiresIn = new Date(savedToken.expiresIn);
    if (expiresIn > new Date()) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${savedToken.accessToken}`,
      };

      return headers;
    } else {
      const api = `${AppConfig.backendApi}/auth/refresh-token`;
      const data = {
        email: savedToken.email,
        refreshToken: savedToken.refreshToken,
      };

      try {
        const fetchResponse = await fetch(api, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (fetchResponse?.ok) {
          const resJson = await fetchResponse.json();

          if (resJson.accessToken) {
            const newToken = resJson;
            newToken.email = savedToken.email;

            localStorage.setItem("token", JSON.stringify(newToken));

            return {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${newToken.accessToken}`,
            };
          } else {
            throw resJson;
          }
        } else {
          throw fetchResponse;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  console.warn("!!! Build ReqHeader have no Authorization field");

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return headers;
}

export function validateJsonResponse(resJson) {
  return !((resJson.code && resJson.message) || resJson.errors);
}
